import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Select,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import Breadcrumbs from "../components/breadcrumbs";
import AlertDetails from "../components/alertDetails";

function Alerts() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const styles = {
    card: {
      h: "76vh",
      overflow: "auto",
      size: { base: "sm", md: "md" },
      borderRadius: "8",
    },
    flexBox: {
      gap: "4",
      alignItems: "center",
    },
    weekText: {
      fontSize: "xs",
    },
    dayText: {
      color: "#00A3FF",
      size: "md",
    },
    deviceHeader: {
      textTransform: "uppercase",
      fontSize: "13px",
    },
    headerFlex: {
      alignItems: "center",
    },
    cardHeader: {
      size: "sm",
    },
    timeStamp: {
      pt: "2",
      fontSize: "sm",
      color: "#929FB1",
    },
    pressureReadingBox: {
      borderRadius: 3,
      px: "8",
      py: "2",
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    pressureReadingText: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#F94144",
      mr: "2",
    },
    pressureReadingThreshold: {
      fontSize: "20px",
      color: "#929FB1",
      mr: "2",
    },
    pressureReadingTopic: {
      pt: "2",
      fontSize: "sm",
      color: "#929FB1",
    },
  };
  return (
    <Box>
      <Breadcrumbs />
      <Card {...styles.card}>
        <CardHeader>
          <Flex {...styles.headerFlex}>
            <Heading {...styles.cardHeader}>Alerts Timeline</Heading>
            <Spacer />
            <Box>
              <Select placeholder="Select a month" size={"sm"} borderRadius={6}>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
        </CardHeader>

        <CardBody>
          <AlertDetails />
        </CardBody>
      </Card>
    </Box>
  );
}

export default Alerts;
