import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Congratulations from "../assets/images/congratulations.jpg";
import ModalBackground from "../assets/images/modalBackground.jpg";

const DeleteDeviceModal = ({ isOpen, onclose, onSave, device }) => {
  console.log("kbfkjsbjsnvj", device);
  const styles = {
    sideimage: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgImage: Congratulations,
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    sideblank: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    main: {
      justifyContent: "center",
      alignItems: "center",
      w: "80%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    row: {
      marginY: "5%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    listrow: {
      marginY: "2%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    listtext: {
      w: "60%",
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "green",
    },
    listbutton: {
      w: "40%",
    },
    mainrow: {
      background: ModalBackground,
    },
    modalBody: {
      justifyContent: "start",
      display: "flex",
      alignItems: "start",
    },
  };

  const onSaveDeleteDevice = async () => {
    const payload = {
      device_assignment_id: device?.device_assignment_id,
    };
    onSave(payload);
  };

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onclose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader {...styles.modalBody}>Delete {device?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Do you want to delete Sl.no - {device?.serial_number} ?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={2}
              size={"sm"}
              fontSiz="12px"
              borderRadius={3}
              fontFamily={"'Poppins', sans-serif"}
              fontWeight={600}
              fontStyle="normal"
              letterSpacing={".05em"}
              textTransform="uppercase"
              onClick={() => {
                onclose();
              }}>
              Cancel
            </Button>
            <Button
              size={"sm"}
              fontSiz="12px"
              borderRadius={3}
              colorScheme="red"
              fontFamily={"'Poppins', sans-serif"}
              fontWeight={600}
              fontStyle="normal"
              letterSpacing={".05em"}
              textTransform="uppercase"
              onClick={() => {
                onSaveDeleteDevice();
              }}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

DeleteDeviceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  device: PropTypes.string.isRequired,
};
export default DeleteDeviceModal;
