import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Spacer,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AuthenticationHeader from "../components/authenticationHeader";
import Loader from "../components/loader";
import { loginAPI } from "../redux/helpers/authAPI";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";
import { changeAuthState, resetState } from "../redux/reducers/authReducer";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const spinner_status = useSelector((state) => state?.auth?.loader_visible);
  const loader_message = useSelector((state) => state?.auth?.loader_message);

  const styles = {
    background: {
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      bgGradient:
        "linear-gradient(135deg, rgb(121, 241, 164) 10%, rgb(14, 92, 173) 100%)",
    },
    topContainer: {
      flexDirection: "column",
      p: { base: 2, md: 10 },
      marginTop: "10vh",
      borderRadius: 10,
      backgroundColor: "whitesmoke",
      justifyContent: "center",
      alignItems: "center",
    },
    stackItems: {
      flexDir: "column",
      mb: "2",
      width: { base: "70vw", md: "100%" },
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      size: "lg",
      fontWeight: "semibold",
    },
    description: {
      fontSize: 14,
      color: "gray",
    },
    formContainer: {
      minW: { base: "100%", md: "468px" },
    },
    formStack: {
      spacing: 4,
      align: "flex-start",
    },
    input: {
      borderRadius: 3,
      size: { base: "sm", md: "md" },
    },
    loginButton: {
      colorScheme: "green",
      size: { base: "sm", md: "md" },
      width: "full",
      fontSize: "12px",
      borderRadius: 3,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },
    linkColor: {
      color: "#1da1f2",
    },
    footerText: {
      flexDirection: "column",
      fontSize: { base: 14, md: 16 },
      textAlign: "center",
    },
  };

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(254, "Email must be at most 254 characters.")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long")
      .max(36, "Password must be at most 36 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter",
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter",
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*\W)/,
        "Password must contain at least one special character",
      ),
  });
  return (
    <Flex {...styles.background}>
      <Loader loading={loading} />
      <AuthenticationHeader />
      <Flex {...styles.topContainer}>
        <Stack {...styles.stackItems}>
          <Heading {...styles.header}>Login</Heading>
          <Text {...styles.description}>Enter credentials to Login</Text>

          {/* Todo: Convert this to modal  */}
          <Box display={spinner_status == true ? "flex" : "none"} width={100}>
            {/* <Spinner thickness="6px" size={"22px"} mr={4} /> */}
            <Text> {loader_message} </Text>
          </Box>

          <Box {...styles.formContainer}>
            <Formik
              initialValues={{
                email: "",
                rememberMe: false,
              }}
              validationSchema={loginValidationSchema}
              onSubmit={(values) => {
                const payload = {
                  email: values?.email,
                  password: values?.password,
                };
                setLoading(true);
                try {
                  dispatch(resetState());
                  const onloginRequest = dispatch(loginAPI(payload));
                  onloginRequest
                    .then((data) => {
                      console.log("onloginRequest", data.payload);
                      if (data.payload?.success === false) {
                        console.log("jbcksjdvbjdbjvbjd");
                        setLoading(false);
                        toast({
                          title: "Incorrect credentials",
                          description: data.payload.message,
                          status: "error",
                          duration: 6000,
                          isClosable: true,
                        });
                      } else if (data?.payload?.is_setup_complete === false) {
                        console.log("onboarding");
                        dispatch(changeAuthState(true));
                        dispatch(getDashboardDataAPI());
                        setLoading(false);
                        // navigate("/onboarding");
                      } else {
                        dispatch(changeAuthState(true));
                        console.log("dashboard");
                        dispatch(getDashboardDataAPI());
                        setLoading(false);
                        // navigate("/dashboard");
                        // TODO: fetch profile
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    });
                } catch (err) {
                  setLoading(false);
                  console.log(err);
                }
              }}>
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack {...styles.formStack}>
                    <FormControl isInvalid={errors?.email && touched?.email}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                      />
                      <FormErrorMessage>{errors?.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={errors?.password && touched?.password}>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          {...styles.input}
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                        />

                        <InputRightElement onClick={handleShowClick}>
                          <ViewIcon />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{errors?.password}</FormErrorMessage>
                    </FormControl>

                    <Button {...styles.loginButton} type="submit">
                      login
                    </Button>
                  </VStack>
                </form>
              )}
            </Formik>
          </Box>
        </Stack>
        <Flex {...styles.footerText}>
          <Box>
            Don’t have an account?{" "}
            <Link {...styles.linkColor} onClick={() => navigate("/signup")}>
              Sign Up
            </Link>
          </Box>
          <Spacer />
          <Link
            {...styles.linkColor}
            onClick={() => navigate("/forgot-password")}>
            Forgot Password?
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}
