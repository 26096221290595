import React from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import Navigations from "./routes/navigation";

function App() {
  return (
    <RouterProvider
      router={Navigations()}
      fallbackElement={<p>Initial Load...</p>}
    />
  );
}

export default App;
