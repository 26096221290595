import React from "react";
import PropTypes from "prop-types";

const Loader = ({ loading }) => {
  if (loading) {
    return (
      <div className="loader-overlay">
        <div
          style={{ overflow: "-moz-hidden-unscrollable" }}>
          <div className="loader"></div>
        </div>
      </div>
    );
  }
};
Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loader;
