import React, { useState } from "react";
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import AddTeamMemberModal from "./addTeamMemberModal";
import { useDispatch, useSelector } from "react-redux";
import { editMemberAPI } from "../redux/helpers/teamAPI";
import Loader from "./loader";
// import SuccessMessageModal from "./successMessageModal";

export default function TeamMembersTable({ teamMembers, refreshTeamMembers }) {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  // const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  // const [isSuccessMessage, setisSuccessMessage] = useState("");
  const token = useSelector((state) => state?.auth?.token);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTeamMember(null);
  };

  const handleModifyClick = (teamMember) => {
    setSelectedTeamMember(teamMember);
    setIsModalOpen(true);
  };

  // const openSuccessModal = (data) => {
  //   setisSuccessModalOpen(data);
  // };

  // const successMessage = (data) => {
  //   setisSuccessMessage(data);
  // };

  const onSave = async (payload) => {
    payload.token = token;
    payload.member_id = selectedTeamMember?.id;
    setLoading(true);
    try {
      const editMemberReq = dispatch(editMemberAPI(payload));
      editMemberReq
        .then((result) => {
          console.log(result);

          if (result?.payload?.success) {
            refreshTeamMembers();
            handleCloseModal();
            setLoading(false);
            toast({
              title: "Success",
              description: result.payload.message,
              status: "success",
              duration: 6000,
              isClosable: true,
            });
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result.payload.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    teamMemberName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      fontSize: "12px",
      px: 6,
      borderColor: "#2b3642",
      borderRadius: 3,
      width: "100%",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },
  };
  return (
    <TableContainer {...styles.tableContainer}>
      <Loader loadding={loading} />
      <Table size={"sm"} variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th width="30%">FIRST NAME</Th>
            <Th width="30%">LAST NAME </Th>
            <Th width="20%">EMAIL</Th>
            <Th width="20%">PHONE</Th>
            <Th width="15%">PRIVILEGE</Th>
            <Th width="15%">STATUS</Th>
            <Th width="15%">ACTIONS</Th>
          </Tr>
        </Thead>
        <Tbody>
          {teamMembers?.map((teamMember, index) => {
            return (
              <Tr key={index}>
                <Td
                  fontWeight={600}
                  textTransform={"capitalize"}
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem"
                  fontSize={14}>
                  {teamMember.first_name}
                </Td>
                <Td
                  fontWeight={600}
                  textTransform={"capitalize"}
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem"
                  fontSize={14}>
                  {teamMember.last_name}
                </Td>
                <Td
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem"
                  fontSize={14}>
                  {teamMember.email}
                </Td>
                <Td
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem"
                  fontSize={14}>
                  {teamMember.phone_number}
                </Td>
                <Td
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem"
                  fontSize={14}>
                  {teamMember.privilege}{" "}
                </Td>
                <Td
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem"
                  fontSize={14}>
                  {teamMember.is_active == true ? "Active" : "Deactivated"}{" "}
                </Td>
                <Td>
                  <Button
                    {...styles.actionButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleModifyClick(teamMember);
                    }}>
                    Modify
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {isModalOpen && (
        <AddTeamMemberModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          onSave={onSave}
          teamMember={selectedTeamMember}
          type={"Edit Team Member"}
          refreshTeamMembers={refreshTeamMembers}
          // openSuccessModal={openSuccessModal}
          // successMessage={successMessage}
        />
      )}
      {/* {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={isSuccessMessage}
          onClose={() => {
            setisSuccessModalOpen(false);
            refreshTeamMembers();
          }}
        />
      )} */}
    </TableContainer>
  );
}

TeamMembersTable.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  refreshTeamMembers: PropTypes.func,
};
