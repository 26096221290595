import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalFooterButtons from "./modalFooterButtons";
import ModalsTriggerButton from "./modalsTriggerButton";
import { Field, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Loader from "./loader";
import {
  addDeviceAPI,
  getDeviceAPI,
  updateDeviceAPI,
} from "../redux/helpers/deviceAPI";
import { MinusIcon } from "@chakra-ui/icons";

const AddDeviceModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  type,
  getDevices,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [SlnoVerified, setSlnoVerified] = useState(false);
  const [verifiedDeviceData, setVerifiedDeviceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const serialNumberRegex = /^[a-zA-Z0-9-]+$/;
  const nameRegex = /^[a-zA-Z0-9\s-]+$/;
  const AddDeviceValidationSchema = !SlnoVerified
    ? Yup.object().shape({
        serial_number: Yup.string()
          .matches(serialNumberRegex, "Invalid serial number format")
          .required("Serial Number is required")
          .max(40, "Serial Number must be at most 40 characters long"),
      })
    : Yup.object().shape({
        device_name: Yup.string()
          .matches(
            nameRegex,
            "Device name must contain only letters, numbers, hyphens and spaces",
          )
          .max(30, "Device Name must be at most 30 characters."),
        min_pressure: Yup.string().required("Min value is required"),
        max_pressure: Yup.string().required("Max value is required"),
      });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 3,
    },
  };

  return (
    <div>
      <Loader loading={loading} />
      <ModalsTriggerButton setIsModalOpen={setIsModalOpen} type="Controller" />

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{type}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!SlnoVerified && (
              <Formik
                initialValues={{
                  serial_number: "",
                }}
                validationSchema={AddDeviceValidationSchema}
                onSubmit={(values, { resetForm }) => {
                  const payload = {
                    serial_number: values?.serial_number,
                  };
                  setLoading(true);
                  try {
                    let addDeviceReq = dispatch(addDeviceAPI(payload));
                    addDeviceReq
                      .then((data) => {
                        setVerifiedDeviceData(data?.payload?.data);

                        if (data?.payload?.success) {
                          setSlnoVerified(true);
                          getDevices();
                          resetForm();
                          setLoading(false);
                          toast({
                            title: "Success",
                            description:
                              "The device has been successfully added to your account.",
                            status: "success",
                            duration: 6000,
                            isClosable: true,
                          });
                        } else {
                          setLoading(false);
                          toast({
                            title: "Error",
                            description: data.payload.message,
                            status: "error",
                            duration: 6000,
                            isClosable: true,
                          });
                        }
                      })
                      .catch((err) => {
                        setLoading(false);
                        console.log(err);
                      });
                  } catch (err) {
                    setLoading(false);
                    console.log(err);
                  }
                }}>
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl
                      isInvalid={errors.serial_number && touched.serial_number}>
                      <FormLabel>
                        <Text fontSize={14} fontWeight={"normal"}>
                          Enter Serial Number
                        </Text>
                      </FormLabel>
                      <Flex>
                        <Field
                          as={Input}
                          {...styles.input}
                          borderRightRadius={0}
                          ref={initialRef}
                          id="serial_number"
                          name="serial_number"
                          type="text"
                          placeholder="Ex: 17466144666"
                          value={values.serial_number}
                          isDisabled={SlnoVerified}
                          onChange={(e) => {
                            handleChange(e.target.value);
                            setFieldValue("serial_number", e.target.value);
                          }}
                        />
                        <Button
                          colorScheme="green"
                          size={"sm"}
                          fontSize={"12px"}
                          px={6}
                          borderLeftRadius={0}
                          borderRightRadius={3}
                          fontFamily={"'Poppins', sans-serif"}
                          fontWeight={600}
                          fontStyle="normal"
                          letterSpacing={".05em"}
                          textTransform="uppercase"
                          isDisabled={SlnoVerified}
                          type="submit">
                          Verify
                        </Button>
                      </Flex>
                      <FormErrorMessage>
                        {errors?.serial_number}
                      </FormErrorMessage>
                    </FormControl>
                    <Flex h={22}>
                      <Text> </Text>
                    </Flex>
                  </form>
                )}
              </Formik>
            )}
            {SlnoVerified && (
              <Formik
                initialValues={{
                  serial_number: verifiedDeviceData?.serial_number,
                  device_name: verifiedDeviceData?.data_points[0]?.device?.name,
                  device_type: verifiedDeviceData?.device_type?.name,
                  device_model: verifiedDeviceData?.device_model?.name,
                  min_pressure: verifiedDeviceData?.data_points[0]?.minimum,
                  max_pressure: verifiedDeviceData?.data_points[0]?.maximum,
                }}
                validationSchema={AddDeviceValidationSchema}
                onSubmit={(values, { resetForm }) => {
                  const payload = {
                    device_name: values?.device_name,
                    device_id: verifiedDeviceData?.id,
                    data_points: [
                      {
                        data_point_id: verifiedDeviceData?.data_points[0]?.id,
                        max: values?.max_pressure,
                        min: values?.min_pressure,
                        unit_id: verifiedDeviceData?.data_points[0]?.unit?.id,
                        monitoring_value:
                          verifiedDeviceData?.data_points[0]?.unit?.type,
                      },
                    ],
                  };
                  setLoading(true);
                  try {
                    let updateDeviceRes = dispatch(updateDeviceAPI(payload));
                    updateDeviceRes
                      .then((data) => {
                        if (data?.payload?.success) {
                          resetForm();
                          setVerifiedDeviceData(null);
                          setSlnoVerified(false);
                          onClose();
                          dispatch(getDeviceAPI());
                          setLoading(false);
                          toast({
                            title: "Success",
                            description: data.payload.message,
                            status: "success",
                            duration: 6000,
                            isClosable: true,
                          });
                        } else {
                          setLoading(false);
                          toast({
                            title: "Error",
                            description: data.payload.message,
                            status: "error",
                            duration: 6000,
                            isClosable: true,
                          });
                        }
                      })
                      .catch((err) => {
                        setLoading(false);
                        console.log(err);
                      });
                  } catch (err) {
                    setLoading(false);
                    console.log(err);
                  }
                }}>
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl
                      isInvalid={errors.serial_number && touched.serial_number}>
                      <FormLabel>Serial Number</FormLabel>
                      {SlnoVerified ? (
                        <Text>{verifiedDeviceData?.serial_number}</Text>
                      ) : (
                        <Field
                          as={Input}
                          {...styles.input}
                          borderRightRadius={0}
                          ref={initialRef}
                          id="serial_number"
                          name="serial_number"
                          type="text"
                          placeholder="Ex: 17466144666"
                          value={values.serial_number}
                          isDisabled={SlnoVerified}
                          onChange={(e) => {
                            handleChange(e.target.value);
                            setFieldValue("serial_number", e.target.value);
                          }}
                        />
                      )}
                      <FormErrorMessage>
                        {errors?.serial_number}
                      </FormErrorMessage>
                    </FormControl>

                    <>
                      <FormControl
                        {...styles.formItem}
                        isInvalid={errors.device_name && touched.device_name}>
                        <FormLabel>Location</FormLabel>
                        <Field
                          as={Input}
                          {...styles.input}
                          id="device_name"
                          name="device_name"
                          type="text"
                          placeholder="Device"
                        />
                        <FormErrorMessage>
                          {errors?.device_name}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        {...styles.formItem}
                        isInvalid={errors.device_type && touched.device_type}>
                        <FormLabel>Type</FormLabel>
                        {SlnoVerified ? (
                          <Text>{verifiedDeviceData?.device_type?.name}</Text>
                        ) : (
                          <Field
                            as={Input}
                            {...styles.input}
                            id="device_type"
                            name="device_type"
                            type="text"
                            placeholder="Type"
                            isDisabled={SlnoVerified}
                          />
                        )}
                        <FormErrorMessage>
                          {errors?.device_type}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        {...styles.formItem}
                        isInvalid={errors.device_model && touched.device_model}>
                        <FormLabel>Model</FormLabel>
                        {SlnoVerified ? (
                          <Text>{verifiedDeviceData?.device_model?.name}</Text>
                        ) : (
                          <Field
                            as={Input}
                            {...styles.input}
                            id="device_model"
                            name="device_model"
                            type="text"
                            placeholder="Model"
                            isDisabled={SlnoVerified}
                          />
                        )}
                        <FormErrorMessage>
                          {errors?.device_model}
                        </FormErrorMessage>
                      </FormControl>

                      <Box {...styles.formItem}>
                        <FormLabel>
                          {verifiedDeviceData?.data_points[0]?.unit?.type}
                        </FormLabel>
                        <Center gap={2}>
                          <FormControl
                            isInvalid={
                              errors.min_pressure && touched.min_pressure
                            }>
                            <Field
                              as={Input}
                              {...styles.input}
                              placeholder="Min pressure in Pa"
                              type={"number"}
                              min={-2000}
                              // value={values?.min_pressure}
                              id="min_pressure"
                              name="min_pressure"
                              onChange={(e) =>
                                setFieldValue(
                                  "min_pressure",
                                  parseInt(e.target.value, 10),
                                )
                              }
                            />
                            <FormErrorMessage>
                              {errors?.min_pressure}
                            </FormErrorMessage>
                          </FormControl>
                          <MinusIcon />
                          <FormControl
                            isInvalid={
                              errors.max_pressure && touched.max_pressure
                            }>
                            <Field
                              as={Input}
                              {...styles.input}
                              placeholder="Max Pressure in Pa"
                              type="number"
                              max={2000}
                              // value={values?.max_pressure}
                              id="max_pressure"
                              name="max_pressure"
                              onChange={(e) =>
                                setFieldValue(
                                  "max_pressure",
                                  parseInt(e.target.value, 10),
                                )
                              }
                            />
                            <FormErrorMessage>
                              {errors?.max_pressure}
                            </FormErrorMessage>
                          </FormControl>
                        </Center>
                      </Box>
                    </>

                    <ModalFooterButtons
                      onClose={onClose}
                      onSave={handleSubmit}
                      type={type}
                    />
                  </form>
                )}
              </Formik>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
AddDeviceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  type: PropTypes.string.isRequired,
  teamMember: PropTypes.object,
  getDevices: PropTypes.func,
  device: PropTypes.object,
};
export default AddDeviceModal;
