import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import Tags from "../components/tags";
import { useSelector } from "react-redux";

export default function AlertDetails() {
  const alertsList = useSelector((state) => state.alert.alertsList);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const styles = {
    dateBox: {
      px: "4",
      py: "2",
      background: "#C9FCFF",
      borderRadius: "6",
      textAlign: "center",
    },
    flexBox: {
      gap: "4",
      alignItems: "center",
    },
    weekText: {
      fontSize: "xs",
    },
    dayText: {
      color: "#00A3FF",
      size: "md",
      textTransform: "uppercase",
    },
    deviceHeader: {
      textTransform: "uppercase",
      fontSize: "13px",
    },
    headerFlex: {
      alignItems: "center",
    },
    cardHeader: {
      size: "sm",
    },
    timeStamp: {
      pt: "2",
      fontSize: "sm",
      color: "#929FB1",
    },
    pressureReadingBox: {
      borderRadius: 3,
      px: { base: "2", lg: "6" },
      py: { base: "1", lg: "2" },
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    pressureReadingText: {
      fontSize: { base: "14px", lg: "18px" },
      fontWeight: "bold",
      color: "#F94144",
      mr: "2",
    },
    pressureReadingThreshold: {
      fontSize: { base: "13px", lg: "18px" },
      color: "#929FB1",
      mr: "2",
    },
    pressureReadingTopic: {
      pt: "2",
      fontSize: "sm",
      color: "#929FB1",
    },
  };
  return (
    <Stack divider={<StackDivider />} spacing="2">
      {alertsList?.map((alert, index) => {
        return (
          <Box key={index}>
            <Grid {...styles.flexBox} templateColumns="repeat(4, 1fr)">
              <GridItem colSpan={{ base: "4", md: "2", lg: "2" }}>
                <Flex>
                  <Box {...styles.dateBox}>
                    <Text {...styles.weekText}>{alert?.day}</Text>
                    <Heading {...styles.dayText}>{alert?.date}</Heading>
                  </Box>
                  <Box px={3}>
                    <Heading {...styles.deviceHeader}>{alert?.device}</Heading>
                    <Text {...styles.timeStamp}>{alert?.time}</Text>
                  </Box>
                  <Box px={3}>
                    <Tags success={alert?.sms} type={"SMS"} size={"sm"} />
                    <Tags success={alert?.email} type={"Email"} size={"sm"} />
                  </Box>
                </Flex>
              </GridItem>
              {!isMobile && <Spacer />}
              <GridItem colSpan={{ base: "4", md: "1", lg: "1" }}>
                <Box {...styles.pressureReadingBox}>
                  <Flex>
                    <Text {...styles.pressureReadingText}>
                      {alert?.reading} Pa
                    </Text>
                    <Text {...styles.pressureReadingThreshold}>
                      {`(${alert?.pressureThresholdStart} - ${alert?.pressureThresholdEnd} Pa)`}
                    </Text>
                  </Flex>
                  <Text {...styles.pressureReadingTopic}>Pressure</Text>
                </Box>
              </GridItem>
            </Grid>
          </Box>
        );
      })}
    </Stack>
  );
}
