import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alertsList: [
    {
      device: "LAB 1",
      day: "Mon",
      date: 10,
      time: "9:00 am",
      email: true,
      sms: true,
      reading: 22,
      pressureThresholdStart: 25,
      pressureThresholdEnd: 75,
    },
    {
      device: "TANK 2",
      day: "Tue",
      date: 11,
      time: "11:30 am",
      email: true,
      sms: true,
      reading: 11,
      pressureThresholdStart: 25,
      pressureThresholdEnd: 75,
    },
    {
      device: "TANK 3",
      day: "Tue",
      date: 11,
      time: "5:00 am",
      email: true,
      sms: true,
      reading: 85,
      pressureThresholdStart: 25,
      pressureThresholdEnd: 75,
    },
    {
      device: "TANK 3",
      day: "Tue",
      date: 11,
      time: "5:00 am",
      email: true,
      sms: true,
      reading: 85,
      pressureThresholdStart: 25,
      pressureThresholdEnd: 75,
    },
    {
      device: "TANK 3",
      day: "Tue",
      date: 11,
      time: "5:00 am",
      email: true,
      sms: true,
      reading: 85,
      pressureThresholdStart: 25,
      pressureThresholdEnd: 75,
    },
    {
      device: "TANK 3",
      day: "Tue",
      date: 11,
      time: "5:00 am",
      email: true,
      sms: true,
      reading: 85,
      pressureThresholdStart: 25,
      pressureThresholdEnd: 75,
    },
  ],
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default alertsSlice.reducer;
