/**
 * Used to Manage Team Member for WG Team ( Internal )
 */
import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addMemberAPI = createAsyncThunk(
  "addMember",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/customer/team`,
        {
          first_name: payload.first_name,
          last_name: payload.last_name,
          role_id: payload.privilege,
          email: payload.email,
          phone_number: payload.phone_number,
        },
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const editMemberAPI = createAsyncThunk("editMember", async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: payload.token,
      },
    };

    let response = await API.put(
      `/customer/team/${payload.member_id}`,
      {
        first_name: payload.first_name,
        last_name: payload.last_name,
        role_id: payload.privilege,
        email: payload.email,
        phone_number: payload.phone_number,
      },
      config,
    );

    return response?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

export const removeMemberAPI = createAsyncThunk(
  "removeMember",
  async (payload) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: payload.token,
        },
      };

      let response = await API.delete(
        `/customer/team/${payload.member_id}`,

        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getMemberAPI = createAsyncThunk("getMember", async (token) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    let response = await API.get(`/customer/team`, config);

    return response?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

/**
 * updateTypesAPI - Use this for both update and soft-delete
 */
export const updateMemberAPI = createAsyncThunk(
  "updateMember",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(
        `/customer/team/${payload?.member_id}`,
        {
          // data to update
        },
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);
