import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Breadcrumbs() {
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter(Boolean);

  const styles = {
    bredcrumbsContainer: {
      mb: 6,
      spacing: "8px",
    },
  };
  return (
    <Flex>
      <Breadcrumb
        {...styles.bredcrumbsContainer}
        separator={<ChevronRightIcon color="gray.500" />}>
        {pathSegments.map((segment, index) => (
          <BreadcrumbItem key={index}>
            <BreadcrumbLink
              fontFamily="'Poppins', sans-serif"
              fontWeight={600}
              fontSize={14}
              fontStyle="normal"
              letterSpacing=".05em"
              textTransform="uppercase"
              href={`/${pathSegments.slice(0, index + 1).join("/")}`}>
              {capitalizeFirstLetter(segment)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Flex>
  );
}

export default Breadcrumbs;
