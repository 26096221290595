import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { ViewIcon } from "@chakra-ui/icons";
import {
  setNewPasswordAPI,
  verifyInvitationIdAPI,
} from "../redux/helpers/authAPI";
import { useDispatch } from "react-redux";
import AuthenticationHeader from "../components/authenticationHeader";
import Loader from "../components/loader";

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const [invitation_id, setInvitationId] = useState("");
  const [userVerified, setUserVerified] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("invitation_id");
    if (id) {
      setInvitationId(id);
      const payload = {
        invitaion_id: id,
      };
      let verifyInvitationIdRes = dispatch(verifyInvitationIdAPI(payload));

      verifyInvitationIdRes.then((data) => {
        console.log("dsvdvfdvdf", data);
        if (data.payload?.user_exists) {
          setLoading(false);
          setUserVerified(false);
        } else {
          setLoading(false);
          toast({
            title: "Error",
            description: data.payload.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } else {
      setLoading(false);
    }
  }, [location.search]);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  const styles = {
    background: {
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      bgGradient:
        "linear-gradient(135deg, rgb(121, 241, 164) 10%, rgb(14, 92, 173) 100%)",
    },
    topContainer: {
      flexDirection: "column",
      p: { base: 2, md: 10 },
      marginTop: "10vh",
      borderRadius: 10,
      backgroundColor: "whitesmoke",
      justifyContent: "center",
      alignItems: "center",
    },
    stackItems: {
      flexDir: "column",
      width: { base: "70vw", md: "100%" },
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      size: "lg",
      fontWeight: "semibold",
    },
    description: {
      fontSize: 14,
      color: "gray",
      textAlign: "center",
    },
    formContainer: {
      minW: { base: "100%", md: "468px" },
    },
    input: {
      borderRadius: 3,
      size: { base: "sm", md: "md" },
    },
    formStack: {
      spacing: 4,
      align: "flex-start",
    },
    loginButton: {
      colorScheme: "green",
      size: { base: "sm", md: "md" },
      width: "full",
      borderRadius: 3,
      fontSize: "12px",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },
    linkColor: {
      color: "#1da1f2",
    },
    footerText: {
      flexDirection: "column",
      fontSize: { base: 14, md: 16 },
      textAlign: "center",
    },
  };

  const resetValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long")
      .max(36, "Password must be at most 36 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter",
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter",
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*\W)/,
        "Password must contain at least one special character",
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  return (
    <Flex {...styles.background}>
      <Loader loading={loading} />
      <AuthenticationHeader />
      <Flex {...styles.topContainer}>
        <Stack {...styles.stackItems}>
          <Heading {...styles.header}>Set New Password</Heading>

          <Box {...styles.formContainer}>
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
                rememberMe: false,
              }}
              validationSchema={resetValidationSchema}
              onSubmit={(values) => {
                const payload = {
                  new_password: values?.password,
                  invitaion_id: invitation_id,
                };
                setLoading(true);
                try {
                  let setNewPasswordRes = dispatch(setNewPasswordAPI(payload));
                  setNewPasswordRes
                    .then((data) => {
                      if (data.payload?.success) {
                        navigate("/login");
                        setLoading(false);
                        toast({
                          title: "Password has been resetted",
                          description: data.payload.message,
                          status: "success",
                          duration: 6000,
                          isClosable: true,
                        });
                      } else {
                        setLoading(false);
                        toast({
                          title: "Error",
                          description: data.payload.message,
                          status: "error",
                          duration: 6000,
                          isClosable: true,
                        });
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    });
                } catch (err) {
                  setLoading(false);
                  console.log(err);
                }
              }}>
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack {...styles.formStack}>
                    <FormControl
                      isInvalid={errors?.password && touched?.password}>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          {...styles.input}
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          isDisabled={userVerified}
                        />

                        <InputRightElement onClick={handleShowClick}>
                          <ViewIcon />
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>{errors?.password}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        errors?.confirmPassword && touched?.confirmPassword
                      }>
                      <FormLabel htmlFor="confirmPassword">
                        Confirm Password
                      </FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          {...styles.input}
                          id="confirmPassword"
                          name="confirmPassword"
                          type={showPassword ? "text" : "password"}
                          placeholder="Confirm your password"
                          isDisabled={userVerified}
                        />

                        <InputRightElement onClick={handleShowClick}>
                          <ViewIcon />
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>
                        {errors?.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>

                    <Button {...styles.loginButton} type="submit">
                      Reset
                    </Button>
                  </VStack>
                </form>
              )}
            </Formik>
          </Box>
        </Stack>

        <Box {...styles.footerText}>
          Already have an account?{" "}
          <Link {...styles.linkColor} onClick={() => navigate("/login")}>
            Login
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
}
