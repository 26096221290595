import React from "react";
import { Box, Text, theme } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { BiAddToQueue, BiBell, BiBoltCircle, BiAnalyse } from "react-icons/bi";

export default function DashboardUserAssetCountSection({
  device_count,
  active_devices_count,
  alarms_detected_count,
}) {
  return (
    <div
      style={{
        width: "100%",
      }}>
      <Box
        display={"flex"}
        alignContent={"center"}
        justifyContent={"space-between"}
        py={2}
        pl={0}>
        <Box
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          flexDirection={"row"}
          alignItems={"center"}
          w={"18rem"}
          p={2}
          bg={theme.colors.blue[400]}
          border={"1px solid #fea756"}
          borderColor={theme.colors.blue[400]}
          borderRadius={3}>
          <Box w={"6rem"}>
            <BiAddToQueue color="white" size={44} />
          </Box>
          <Box display={"flex"} flexDir={"column"}>
            <Text
              fontSize={"x-large"}
              fontWeight={"bold"}
              color={"white"}
              fontFamily="'Barlow', sans-serif"
              letterSpacing="0.05rem"
              textAlign={"center"}
              fontStyle="normal">
              {device_count != undefined ? device_count : 0}
            </Text>
            <Text
              color={"white"}
              alignSelf={"center"}
              fontFamily="'Barlow', sans-serif"
              letterSpacing="0.05rem"
              fontStyle="normal"
              fontWeight={600}
              fontSize={14}>
              Available Devices
            </Text>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          flexDirection={"row"}
          alignItems={"center"}
          w={"18rem"}
          p={2}
          bg={theme.colors.teal[400]}
          border={"1px solid #68e365"}
          borderColor={theme.colors.teal[400]}
          borderRadius={3}>
          <Box w={"6rem"}>
            <BiBoltCircle color="white" size={44} />
          </Box>
          <Box display={"flex"} flexDir={"column"}>
            <Text
              fontSize={"x-large"}
              fontWeight={"bold"}
              color={"white"}
              fontFamily="'Barlow', sans-serif"
              letterSpacing="0.05rem"
              textAlign={"center"}
              fontStyle="normal">
              {active_devices_count != undefined ? active_devices_count : 0}
            </Text>
            <Text
              color={"white"}
              alignSelf={"center"}
              fontFamily="'Barlow', sans-serif"
              letterSpacing="0.05rem"
              fontStyle="normal"
              fontWeight={600}
              fontSize={14}>
              Active Devices
            </Text>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          flexDirection={"row"}
          alignItems={"center"}
          w={"18rem"}
          p={2}
          bg={theme.colors.orange[400]}
          border={"1px solid #68e365"}
          borderColor={theme.colors.orange[400]}
          borderRadius={3}>
          <Box w={"6rem"}>
            <BiBell color="white" size={44} />
          </Box>
          <Box display={"flex"} flexDir={"column"}>
            <Text
              fontSize={"x-large"}
              fontWeight={"bold"}
              color={"white"}
              fontFamily="'Barlow', sans-serif"
              letterSpacing="0.05rem"
              textAlign={"center"}
              fontStyle="normal">
              {alarms_detected_count != undefined ? alarms_detected_count : 0}
            </Text>
            <Text
              color={"white"}
              alignSelf={"center"}
              fontFamily="'Barlow', sans-serif"
              letterSpacing="0.05rem"
              fontStyle="normal"
              fontWeight={600}
              fontSize={14}>
              Alarms Detected
            </Text>
          </Box>
        </Box>

        <Box
          display={"flex"}
          flexDir={"column"}
          justifyContent={"center"}
          flexDirection={"row"}
          alignItems={"center"}
          w={"18rem"}
          p={2}
          bg={theme.colors.red[400]}
          border={"1px solid #68e365"}
          borderColor={theme.colors.red[400]}
          borderRadius={3}>
          <Box w={"6rem"}>
            <BiAnalyse color="white" size={44} />
          </Box>
          <Box display={"flex"} flexDir={"column"}>
            <Text
              fontSize={"x-large"}
              fontWeight={"bold"}
              color={"white"}
              fontFamily="'Barlow', sans-serif"
              letterSpacing="0.05rem"
              fontStyle="normal"
              textAlign={"center"}>
              {alarms_detected_count != undefined ? alarms_detected_count : 0}
            </Text>
            <Text
              color={"white"}
              alignSelf={"center"}
              fontFamily="'Barlow', sans-serif"
              letterSpacing="0.05rem"
              fontStyle="normal"
              fontWeight={600}
              fontSize={14}>
              Action Needed
            </Text>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

DashboardUserAssetCountSection.propTypes = {
  device_count: PropTypes.number.isRequired,
  active_devices_count: PropTypes.number.isRequired,
  alarms_detected_count: PropTypes.number.isRequired,
};
