import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  // Select,
  Spinner,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import SignUpModal from "../components/signupModal";
import { signUpAPI, userRolesAPI } from "../redux/helpers/authAPI";
import AuthenticationHeader from "../components/authenticationHeader";
import Loader from "../components/loader";

export default function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const [showPassword, setShowPassword] = useState(false);
  // const [stepper, setStepper] = useState(0);
  const [loading, setLoading] = useState(false);
  const spinner_status = useSelector((state) => state?.auth?.loader_visible);
  const loader_message = useSelector((state) => state?.auth?.loader_message);
  const roles = useSelector((state) => state?.auth?.roles);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const [userDetails, setUserDetails] = useState({
  //   first_name: null,
  //   last_name: null,
  //   role_id: "null",
  //   email: null,
  //   phone_number: null,
  //   password: null,
  //   organization_name: null,
  // });

  const handleShowClick = () => setShowPassword(!showPassword);

  useEffect(() => {
    dispatch(userRolesAPI());
  }, []);

  useEffect(() => {
    console.log("updated/changed roles", roles);
  }, [roles]);

  const styles = {
    background: {
      flexDirection: "column",
      alignItems: "center",
      bgGradient:
        "linear-gradient(135deg, rgb(121, 241, 164) 10%, rgb(14, 92, 173) 100%)",
    },
    topContainer: {
      flexDirection: "column",
      p: { base: 2, md: 10 },
      margin: 30,
      borderRadius: 10,
      backgroundColor: "whitesmoke",
      justifyContent: "center",
      alignItems: "center",
    },
    stackItems: {
      flexDir: "column",
      mb: "2",
      width: { base: "70vw", md: "100%" },
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      size: "lg",
      fontWeight: "semibold",
    },
    description: {
      fontSize: 14,
      color: "gray",
    },
    formContainer: {
      minW: { base: "100%", md: "468px" },
    },
    formStack: {
      spacing: 4,
      align: "flex-start",
    },
    input: {
      borderRadius: 3,
      size: { base: "sm", md: "md" },
    },
    loginButton: {
      colorScheme: "green",
      size: { base: "sm", md: "md" },
      width: "full",
      fontSize: "12px",
      borderRadius: 3,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },
    linkColor: {
      color: "#1da1f2",
    },
    footerText: {
      flexDirection: "column",
      fontSize: { base: 14, md: 16 },
      textAlign: "center",
    },
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const nameRegex = /^[a-zA-Z\s]+$/;

  const personalDetailsValidationSchema = Yup.object().shape({
    organization_name: Yup.string()
      .matches(
        nameRegex,
        "Organization Name must contain only letters and spaces",
      )
      .max(30, "Organization Name must be at most 30 characters.")
      .required("Organization Name is required"),

    first_name: Yup.string()
      .matches(nameRegex, "First name must contain only letters and spaces")
      .max(30, "First Name must be at most 30 characters.")
      .required("First Name is required"),
    last_name: Yup.string()
      .matches(nameRegex, "Last Name must contain only letters and spaces")
      .max(30, "Last Name must be at most 30 characters.")
      .required("Last Name is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]{6,14}$/, "Enter valid Phone Number (e.g., +81-1223-333)")
      .required("Phone Number is required"),
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(254, "Email must be at most 254 characters.")
      .required("Email is required"),
    // privilege: Yup.string().required("Privilege is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long")
      .max(36, "Password must be at most 36 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter",
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter",
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*\W)/,
        "Password must contain at least one special character",
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate("/login");
  };

  return (
    <Flex
      {...styles.background}
      bgGradient="linear-gradient(135deg, rgb(121, 241, 164) 10%, rgb(14, 92, 173) 100%)">
      <AuthenticationHeader />
      <Loader loading={loading} />
      <Flex {...styles.topContainer}>
        <Stack {...styles.stackItems}>
          {
            <>
              <Heading {...styles.header}>Sign Up</Heading>
              <Text {...styles.description}>
                Create account and register your devices
              </Text>
            </>
          }

          <Box display={spinner_status == true ? "flex" : "none"} width={100}>
            <Spinner thickness="6px" size={"22px"} mr={4} />
            <Text> {loader_message} </Text>
          </Box>

          <Box {...styles.formContainer}>
            {
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  phone_number: "",
                  privilege: roles?.filter((role) => role.name == "Admin")[0],
                  email: "",
                  password: "",
                  confirmPassword: "",
                  organization_name: "",
                }}
                validationSchema={personalDetailsValidationSchema}
                onSubmit={(values) => {
                  console.log(values, "values");
                  const payload = {
                    first_name: values?.first_name,
                    last_name: values?.last_name,
                    role_id: roles?.filter((role) => role.name == "Admin")[0]
                      ?.id,
                    email: values?.email,
                    phone_number: values?.phone_number,
                    password: values?.password,

                    organization_name: values?.organization_name,
                    org_contact_person_first_name: values?.first_name,
                    org_contact_person_last_name: values?.last_name,
                    org_contact_person_email: values?.email,
                    org_contact_person_phone_number: values?.phone_number,
                  };
                  // (payload);
                  // setStepper(stepper + 1);
                  setLoading(true);
                  try {
                    let signupRes = dispatch(signUpAPI(payload));
                    signupRes
                      .then((data) => {
                        console.log("onSignupRequest", data.payload);

                        if (data.payload?.success === false) {
                          setLoading(false);
                          toast({
                            title: "Error",
                            description: data.payload.message,
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                          });
                        } else {
                          handleModalOpen();
                          setLoading(false);
                          toast({
                            title: "Success",
                            description: data.payload.message,
                            status: "success",
                            duration: 4000,
                            isClosable: true,
                          });
                        }
                      })
                      .catch((error) => {
                        setLoading(false);
                        console.log(error);
                      });
                  } catch (err) {
                    setLoading(false);
                    console.log(err);
                  }
                }}>
                {({
                  handleSubmit,
                  errors,
                  touched,
                  //handleChange,
                  // setFieldValue,
                  // values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack {...styles.formStack}>
                      <>
                        <FormControl
                          isInvalid={
                            errors?.organization_name &&
                            touched?.organization_name
                          }>
                          <FormLabel htmlFor="phone_number">
                            Organization
                          </FormLabel>
                          <Field
                            as={Input}
                            {...styles.input}
                            id="organization_name"
                            name="organization_name"
                            type="text"
                            placeholder="ABC Labs"
                          />
                          <FormErrorMessage>
                            {errors?.organization_name}
                          </FormErrorMessage>
                        </FormControl>
                        <Flex width={"100%"} gap={4}>
                          <FormControl
                            isInvalid={
                              errors?.first_name && touched?.first_name
                            }>
                            <FormLabel htmlFor="first_name">
                              First Name
                            </FormLabel>
                            <Field
                              as={Input}
                              {...styles.input}
                              id="first_name"
                              name="first_name"
                              type="text"
                              placeholder="Enter your First Name"
                            />
                            <FormErrorMessage>
                              {errors?.first_name}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={errors?.last_name && touched?.last_name}>
                            <FormLabel htmlFor="last_name">Last Name</FormLabel>
                            <Field
                              as={Input}
                              {...styles.input}
                              id="last_name"
                              name="last_name"
                              type="text"
                              placeholder="Enter your Last Name"
                            />
                            <FormErrorMessage>
                              {errors?.last_name}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>

                        <FormControl
                          isInvalid={
                            errors?.phone_number && touched?.phone_number
                          }>
                          <FormLabel htmlFor="phone_number">
                            Phone Number
                          </FormLabel>
                          <Field
                            as={Input}
                            {...styles.input}
                            id="phone_number"
                            name="phone_number"
                            type="text"
                            placeholder="Enter your Phone Number"
                          />
                          <FormErrorMessage>
                            {errors?.phone_number}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={errors?.email && touched?.email}>
                          <FormLabel htmlFor="email"> Work Email</FormLabel>
                          <Field
                            as={Input}
                            {...styles.input}
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                          />
                          <FormErrorMessage>{errors?.email}</FormErrorMessage>
                        </FormControl>
                        {/* <FormControl
                          {...styles.formItem}
                          isInvalid={errors.privilege && touched.privilege}>
                          <FormLabel>Privilege</FormLabel>
                          <Select
                            {...styles.input}
                            placeholder="Select option"
                            name="privilege"
                            value={values.privilege}
                            onChange={(e) => {
                              handleChange(e.target.value);
                              console.log("e.target.value", e.target.value);
                              setFieldValue("privilege", e.target.value);
                            }}>
                            {roles.map((role) => {
                              return (
                                <option key={role.id} value={role.id}>
                                  {role.name}
                                </option>
                              );
                            })}
                          </Select>
                          <FormErrorMessage>
                            {errors?.privilege}
                          </FormErrorMessage>
                        </FormControl> */}
                        <FormControl
                          isInvalid={errors?.password && touched?.password}>
                          <FormLabel htmlFor="password">Password</FormLabel>
                          <InputGroup>
                            <Field
                              as={Input}
                              id="password"
                              name="password"
                              {...styles.input}
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter your password"
                            />

                            <InputRightElement onClick={handleShowClick}>
                              <ViewIcon />
                            </InputRightElement>
                          </InputGroup>

                          <FormErrorMessage>
                            {errors?.password}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            errors?.confirmPassword && touched?.confirmPassword
                          }>
                          <FormLabel htmlFor="confirmPassword">
                            Confirm Password
                          </FormLabel>
                          <InputGroup>
                            <Field
                              as={Input}
                              id="confirmPassword"
                              name="confirmPassword"
                              {...styles.input}
                              type={showPassword ? "text" : "password"}
                              placeholder="Confirm your password"
                            />

                            <InputRightElement onClick={handleShowClick}>
                              <ViewIcon />
                            </InputRightElement>
                          </InputGroup>

                          <FormErrorMessage>
                            {errors?.confirmPassword}
                          </FormErrorMessage>
                        </FormControl>
                      </>

                      <Button {...styles.loginButton} type="submit">
                        Signup
                      </Button>
                    </VStack>
                  </form>
                )}
              </Formik>
            }
          </Box>
        </Stack>
        <Box {...styles.footerText}>
          Already have an account?{" "}
          <Link {...styles.linkColor} onClick={() => navigate("/login")}>
            Login
          </Link>
        </Box>
        <SignUpModal isOpen={isModalOpen} onclose={handleModalClose} />
      </Flex>
    </Flex>
  );
}
