import { AddIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";

export default function ModalsTriggerButton({ type, setIsModalOpen }) {
  const styles = {
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "green",
      borderRadius: 3,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      fontSize: "12px",
      textTransform: "uppercase",
    },
  };
  return (
    <Box>
      <Button
        {...styles.button}
        rightIcon={<AddIcon />}
        onClick={() => setIsModalOpen(true)}>
        Add {type}
      </Button>
    </Box>
  );
}

ModalsTriggerButton.propTypes = {
  type: PropTypes.string.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
