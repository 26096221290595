import { createSlice } from "@reduxjs/toolkit";
import {
  addMemberAPI,
  editMemberAPI,
  getMemberAPI,
  removeMemberAPI,
} from "../helpers/teamAPI";

const initialState = {
  teamMemberData: [],
};

export const teamMemberSlice = createSlice({
  name: "teamMember",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addMemberAPI.fulfilled, (state, action) => {
        console.log("addMemberAPI", action);
        // state.types = action?.payload?.data;
      })
      .addCase(addMemberAPI.rejected, (state, action) => {
        console.log("addMemberAPI", action);
        // state.types = action?.payload?.data;
      })
      .addCase(editMemberAPI.fulfilled, (state, action) => {
        console.log("editMemberAPI", action);
      })
      .addCase(editMemberAPI.rejected, (state, action) => {
        console.log("editMemberAPI", action);
      })
      .addCase(removeMemberAPI.fulfilled, (state, action) => {
        console.log("removeMemberAPI", action);
      })
      .addCase(removeMemberAPI.rejected, (state, action) => {
        console.log("removeMemberAPI", action);
      })
      .addCase(getMemberAPI.fulfilled, (state, action) => {
        console.log("getMemberAPI", action);
        state.teamMemberData = action?.payload?.data;
      })
      .addCase(getMemberAPI.rejected, (state, action) => {
        console.log("getMemberAPI", action);
        state.teamMemberData = [];
      });
  },
});

export default teamMemberSlice.reducer;
