import { Box, Image } from "@chakra-ui/react";
import React from "react";
import Branding from "../assets/images/wintergardenz-logo.webp";

export default function AuthenticationHeader() {
  return (
    <Box pt={10} alignItems={"flex-start"}>
      <Image height={"6vh"} src={Branding} alt="Winter Gardenz"></Image>
    </Box>
  );
}
