import React from "react";
import DeviceDetails from "../components/deviceDetails";
import DeviceGraphTabs from "../components/deviceGraphTabs";
import { Box, Flex } from "@chakra-ui/react";
import Breadcrumbs from "../components/breadcrumbs";

function Device() {
  const styles = {
    flexContainer: {
      gap: 4,
      flexDirection: "column",
    },
  };
  return (
    <Box>
      <Breadcrumbs />
      <Flex {...styles.flexContainer}>
        <DeviceDetails />
        <DeviceGraphTabs />
      </Flex>
    </Box>
  );
}

export default Device;
