import {
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Congratulations from "../assets/images/congratulations.jpg";
import ModalBackground from "../assets/images/modalBackground.jpg";

const SignUpModal = ({ isOpen, onclose }) => {
  const styles = {
    sideimage: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgImage: Congratulations,
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    sideblank: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    main: {
      justifyContent: "center",
      alignItems: "center",
      w: "80%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    row: {
      marginY: "5%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    listrow: {
      marginY: "2%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    listtext: {
      w: "60%",
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "green",
    },
    listbutton: {
      w: "40%",
    },
    mainrow: {
      background: ModalBackground,
    },
    modalCenter: {
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onclose} size="sm" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader {...styles.modalCenter}>Congratulations!!!</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ ...styles.modalCenter, flexDirection: "column" }}>
            <Grid
              {...styles.row}
              // templateAreas={`"nav main main"`}
              // gridTemplateRows={"50px 1fr 30px"}
              // gridTemplateColumns={"100px 1fr"}
              // h="200px"
              gap="1">
              {/* <Text>Welcome!!! </Text> */}
              <Text>
                Thank you for signing up with Winter Gardenz. We have sent an
                email. For uninterrupted usage, please verify your account by
                clicking the link in the email.
              </Text>
            </Grid>
          </ModalBody>
          <ModalFooter px={0} {...styles.modalCenter}>
            <Button
              borderRadius={3}
              fontFamily={"'Poppins', sans-serif"}
              fontWeight={600}
              fontSize={"12px"}
              fontStyle="normal"
              letterSpacing={".05em"}
              textTransform="uppercase"
              onClick={() => {
                onclose();
              }}>
              Proceed to login
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

SignUpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
};
export default SignUpModal;
