import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import Breadcrumbs from "../components/breadcrumbs";

function Profile() {
  const location = useLocation();
  //   const navigate = useNavigate();
  const { customer } = location?.state || {};

  const user = useSelector((state) => state?.auth?.userProfile);

  console.log("kjkscjsdhjshj", user);

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "center",
      direction: "row",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
      fontFamily: "'Barlow', sans-serif",
    },
    subheaderItems: {
      alignItems: "center",
      marginTop: "10px",
    },
    name: {
      alignItems: "center",
      fontSize: "1.8rem",
      fontWeight: "500",
      fontFamily: "'Barlow', sans-serif",
    },
    org: {
      alignItems: "center",
      fontSize: "1.6rem",
      fontWeight: "400",
      fontFamily: "'Barlow', sans-serif",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
      fontFamily: "'Barlow', sans-serif",
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  };
  //   const handleClick = () => {
  //     navigate(`${location?.pathname}/assign-device`, { state: { customer } });
  //   };

  console.log(customer);

  return (
    <Box>
      <Breadcrumbs />
      <Card size={{ base: "sm", md: "md" }}>
        <CardBody>
          <Flex {...styles.flexContainer}>
            <Flex
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}>
              <Avatar {...styles.avatar} name={user?.name} size="2xl" />
              <Box
                ml={4}
                display="flex"
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}>
                <Flex>
                  <Heading {...styles.deviceHeader}>{user?.name}</Heading>
                </Flex>
                <Flex {...styles.name} mt={1}>
                  {user?.first_name} {user?.last_name}
                </Flex>

                {/* <Flex {...styles.subheaderItems} mt={1}> */}
                <Flex {...styles.subheaderItems}>
                  <PhoneIcon />
                  <Text {...styles.subheaderText}>{user?.phone_number} </Text>
                </Flex>
                <Flex {...styles.subheaderItems} style={{ marginLeft: 16 }}>
                  <EmailIcon />
                  <Text {...styles.subheaderText}>{user?.email} </Text>
                </Flex>
                <Flex {...styles.org} mt={1}>
                  {user?.organization}
                </Flex>
                {/* </Flex> */}
              </Box>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
}

export default Profile;
