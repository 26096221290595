import { createSlice } from "@reduxjs/toolkit";
import {
  addDeviceAPI,
  deviceConfigHistoryAPI,
  getDeviceAPI,
  getSingleDeviceAPI,
  updateDeviceAPI,
} from "../helpers/deviceAPI";

const initialState = {
  devices: [],
  activeDevice: {},
  activeDeviceConfigHistory: [],
};

export const controllersSlice = createSlice({
  name: "controllers",
  initialState,
  reducers: {
    setActiveDevice: (state, action) => {
      state.activeDevice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addDeviceAPI.fulfilled, (state, action) => {
        console.log("addDeviceAPI", action);
      })
      .addCase(addDeviceAPI.rejected, (state, action) => {
        console.log("addDeviceAPI", action);
      })
      .addCase(getDeviceAPI.fulfilled, (state, action) => {
        state.devices = action?.payload?.data;
      })
      .addCase(getDeviceAPI.rejected, (state, action) => {
        console.log("loading devices failed", action);
      })
      .addCase(getSingleDeviceAPI.fulfilled, (state, action) => {
        console.log("getSingleDeviceAPI", action);
      })
      .addCase(getSingleDeviceAPI.rejected, (state, action) => {
        console.log("getSingleDeviceAPI", action);
      })
      .addCase(updateDeviceAPI.fulfilled, (state, action) => {
        console.log("updateDeviceAPI success", action);
      })
      .addCase(updateDeviceAPI.rejected, (state, action) => {
        console.log("updateDeviceAPI failed", action);
      })
      .addCase(deviceConfigHistoryAPI.fulfilled, (state, action) => {
        state.activeDeviceConfigHistory = action?.payload?.data;
      })
      .addCase(deviceConfigHistoryAPI.rejected, (state, action) => {
        // state.models = action?.payload;
        console.log("deviceConfigHistoryAPI", action);
      });
  },
});

export const { setActiveDevice } = controllersSlice.actions;

export default controllersSlice.reducer;
