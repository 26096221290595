import { Box, Flex, Spacer, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ControllersTable from "../components/controllersTable";
import Breadcrumbs from "../components/breadcrumbs";
import ControllerSearchFilters from "../components/controllersSearchFilters";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteDeviceAPI, getDeviceAPI } from "../redux/helpers/deviceAPI";
import AddDeviceModal from "../components/addDeviceModal";
import { setActiveDevice } from "../redux/reducers/controllerReducer";
import DeleteDeviceModal from "../components/deleteDeviceModal";
import Loader from "../components/loader";

function Controllers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const devices = useSelector((state) => state.controller.devices);
  const [singleDevice, setSingleDevice] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  useEffect(() => {
    getDevices();
  }, []);

  const handleDeleteModalOpen = (device) => {
    console.log("knfknskjnkjnkv");
    setIsDeleteModalOpen(true);
    setSingleDevice(device);
  };

  const getDevices = () => {
    let getDevicesRes = dispatch(getDeviceAPI());
    getDevicesRes.then((data) => {
      setFilteredDevices(data?.payload?.data);
    });
  };

  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 3,
    },
  };

  const [searchParams, setSearchParams] = useState({
    name: "",
    status: "",
  });
  const [filteredDevices, setFilteredDevices] = useState(devices);

  const handleSearch = () => {
    const newFilteredDevices = devices?.filter((device) => {
      const nameMatch =
        !searchParams?.name ||
        device?.name
          ?.toLowerCase()
          .includes(searchParams?.name?.toLowerCase()) ||
        device?.serial_number
          ?.toLowerCase()
          .includes(searchParams?.name?.toLowerCase());
      const statusMatch =
        !searchParams?.status ||
        device?.status?.toLowerCase() === searchParams?.status?.toLowe;

      return nameMatch && statusMatch;
    });

    setFilteredDevices(newFilteredDevices);
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
      status: "",
    });

    setFilteredDevices(devices);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveDeleteModel = (payload) => {
    payload = { ...payload };
    console.log("onSaveDeleteModel payload", payload);
    setLoading(true);
    try {
      let deleteDeviceRes = dispatch(deleteDeviceAPI(payload));
      deleteDeviceRes
        .then((data) => {
          if (data?.payload?.success) {
            // setSuccessMessage("Device Deleted successfully");
            dispatch(getDeviceAPI())
              .then((devices) => {
                setLoading(false);
                setFilteredDevices(devices?.payload?.data);
              })
              .catch((error) => {
                setLoading(false);
                console.log(error);
              });
            handleCloseDeleteModal();
            setLoading(false);
            toast({
              title: "Success",
              description: data.payload.message,
              status: "success",
              duration: 6000,
              isClosable: true,
            });
          } else {
            handleCloseDeleteModal();
            setLoading(false);
            toast({
              title: "Error",
              description: data.payload.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleRowClick = (selectedDevice) => {
    const completeDeviceInfo = devices.filter(
      (device) => device.id == selectedDevice?.id,
    );
    dispatch(setActiveDevice(completeDeviceInfo[0]));
    navigate("/controllers/device", { state: { selectedDevice } });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isOpenedForModify, setIsOpenedForModify] = useState(false);
  // const [singleDevice, setSingleDevice] = useState("");

  // const handleModalOpen = (device, modify = false) => {
  //   setIsOpenedForModify(modify);

  //   setIsModalOpen(true);
  //   device = { ...device, update: true };
  //   setSingleDevice(device);
  // };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />
        <AddDeviceModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          type="Add Controller"
          getDevices={getDevices}
        />
      </Flex>
      <Box {...styles.topBox}>
        <ControllerSearchFilters
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={singleDevice}
          onSave={onSaveDeleteModel}
        />
        <ControllersTable
          devices={filteredDevices}
          handleRowClick={handleRowClick}
          // handleModalOpen={handleModalOpen}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCloseModal={handleCloseModal}
          getDevices={getDevices}
          handleDeleteModalOpen={handleDeleteModalOpen}
        />
        {/* {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`${successMessage}!`}
          onClose={() => {
            setisSuccessModalOpen(false);
            reloadDataForAccount();
          }}
        />
      )} */}
      </Box>
    </Box>
  );
}

export default Controllers;
