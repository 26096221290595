import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import {
  BiAlarm,
  BiCog,
  BiDevices,
  BiLogOut,
  BiSolidDashboard,
  BiSolidUserDetail,
} from "react-icons/bi";
import React from "react";
import "../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { resetState } from "../redux/reducers/authReducer";
import { useDispatch } from "react-redux";

export default function SidebarMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const pathSegments = location?.pathname?.split("/").filter(Boolean);

  const styles = {
    topContainer: {
      height: { base: "90vh", lg: "90vh" },
      width: { base: "14vw", md: "8vw", lg: "16vw" },
      backgroundColor: "#1A4D2E",
      padding: { base: "12px", lg: "30px" },
      flexDirection: "column",
    },
    stack: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      alignItems: "flex-start",
    },
    menuItem: {
      alignItems: "center",
      py: 2,
      px: 2,
      w: { base: "8vw", lg: "12vw" },
    },
    highlightedMenuItem: {
      alignItems: "center",
      borderRadius: 3,
      py: 2,
      px: 2,
      backgroundColor: "#4F6F52",
      w: { base: "8vw", lg: "12vw" },
    },
    sidebarIcon: {
      color: "white",
      size: 18,
    },
    menuText: {
      color: "white",
      justifyContent: "flex-start",
      paddingLeft: "8px",
      display: { base: "none", lg: "block" },
      fontSize: "12px",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },
    logoutButton: {
      size: "sm",
      variant: "outline",
      colorScheme: "whiteAlpha",
      fontSize: "12px",
      alignItems: "center",
      borderRadius: 3,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },
    buttonText: {
      color: "white",
      justifyContent: "flex-start",
      display: { base: "none", lg: "block" },
    },
  };

  const menuItems = [
    {
      path: "/dashboard",
      label: "Dashboard",
      icon: <BiSolidDashboard {...styles.sidebarIcon} />,
    },
    {
      path: "/controllers",
      label: "Controllers",
      icon: <BiDevices {...styles.sidebarIcon} />,
    },
    {
      path: "/team",
      label: "Team",
      icon: <BiSolidUserDetail {...styles.sidebarIcon} />,
    },
    {
      path: "/alerts",
      label: "Alerts",
      icon: <BiAlarm {...styles.sidebarIcon} />,
    },
    {
      path: "/profile",
      label: "Profile",
      icon: <BiCog {...styles.sidebarIcon} />,
    },
  ];

  return (
    <Flex {...styles.topContainer}>
      <Box {...styles.stack}>
        {menuItems.map((item) => (
          <Flex
            key={item.path}
            {...(item.path === `/${pathSegments[0]}`
              ? styles.highlightedMenuItem
              : styles.menuItem)}
            onClick={() => navigate(item.path)}>
            {item.icon}
            <Box as="button" {...styles.menuText}>
              {item.label}
            </Box>
          </Flex>
        ))}
      </Box>
      <Spacer />
      <Button
        {...styles.logoutButton}
        rightIcon={<BiLogOut color="white" aria-label="Logout" size={16} />}
        onClick={() => {
          navigate("/");
          dispatch(resetState());
        }}>
        <Box {...styles.buttonText}>Logout</Box>
      </Button>
    </Flex>
  );
}
