import { Box, Grid, GridItem } from "@chakra-ui/layout";
import { Button, Flex, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddDeviceModal from "../components/addDeviceModal";
import Breadcrumbs from "../components/breadcrumbs";
import DashboardUserAssetCountSection from "../components/dashboardUserAssetCountSection";
import DeviceWatchlist from "../components/deviceWatchlist";
import Loader from "../components/loader";
import { getDeviceAPI } from "../redux/helpers/deviceAPI";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";

// import RecentAlertList from "../components/recentAlertList";
function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // Start Add Device Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getDevices = () => {
    dispatch(getDeviceAPI());
  };

  // End Add Device Modal

  // const options = {
  //   title: {
  //     text: "Device Data Over Time",
  //     left: "left",
  //     padding: [20, 0, 0, 20],
  //   },
  //   tooltip: {
  //     trigger: "axis",
  //   },
  //   legend: {
  //     data: ["Device 1", "Device 2", "Device 3", "Device 4", "Device 5"],
  //     top: "12%",
  //   },
  //   grid: {
  //     left: "3%",
  //     right: "4%",
  //     bottom: "3%",
  //     containLabel: true,
  //   },
  //   xAxis: {
  //     type: "category",
  //     boundaryGap: false,
  //     data: [
  //       "02 PM",
  //       "04 PM",
  //       "06 PM",
  //       "08 PM",
  //       "10 PM",
  //       "12 PM",
  //       "02 AM",
  //       "04 AM",
  //       "06 AM",
  //       "08 AM",
  //       "10 AM",
  //       "12 AM",
  //     ],
  //   },
  //   yAxis: {
  //     type: "value",
  //   },
  //   series: [
  //     {
  //       name: "Device 1",
  //       type: "line",
  //       stack: "Total",
  //       data: [25, 30, 32, 34, 30, 39, 45, 50, 60, 64, 70],
  //     },
  //     {
  //       name: "Device 2",
  //       type: "line",
  //       stack: "Total",
  //       data: [20, 34, 39, 48, 59, 60, 70, 75, 65, 69, 70],
  //     },
  //     {
  //       name: "Device 3",
  //       type: "line",
  //       stack: "Total",
  //       data: [50, 32, 20, 25, 36, 50, 60, 64, 70, 75, 65],
  //     },
  //     {
  //       name: "Device 4",
  //       type: "line",
  //       stack: "Total",
  //       data: [32, 35, 39, 40, 56, 59, 63, 70, 75, 77, 80],
  //     },
  //     {
  //       name: "Device 5",
  //       type: "line",
  //       stack: "Total",
  //       data: [82, 76, 70, 60, 70, 75, 70, 60, 56, 50, 45],
  //     },
  //   ],
  // };
  const styles = {
    topGrid: {
      gap: 4,
      templateColumns: "repeat(2, 1fr)",
    },
    topContainerWatchlist: {
      overflowY: "scroll'",
      // h: { base: "68vh", md: "32vh", lg: "38vh" },
      h: "100%",
      w: { base: "100%", lg: "100%" },
      colSpan: { base: "4", md: "2", lg: "2" },
      pr: 2,
      display: "flex",
      flexDirection: "column",
    },
    cardHeader: {
      size: "sm",
    },
    recentAlertsCard: {
      w: { base: "100%", lg: "31vw" },
      overflowY: "auto",
      h: "37vh",
      size: "sm",
      p: 0,
    },
    sideList: {
      colSpan: { base: "2", md: "2", lg: "1" },
    },
    lineGraph: {
      colSpan: { base: "2", md: "2", lg: "1" },
    },
    divider: {
      w: "92%",
      color: "gray.200",
    },
    chartStyles: {
      w: { base: "100vw", lg: "49.5vw" },
      height: "37vh",
      backgroundColor: "white",
      borderRadius: 3,
      p: 10,
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "green",
      borderRadius: 3,
      fontSize: "12px",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },
  };

  const devices_count = useSelector(
    (state) => state?.summary?.total_devices_count,
  );
  const active_devices_count = useSelector(
    (state) => state?.summary?.active_devices_count,
  );

  // const recently_added_devices = useSelector(
  //   (state) => state?.summary?.recently_added_devices,
  // );

  const recently_updated_devices = useSelector(
    (state) => state?.controller?.devices,
  );

  const alarms_detected_count = useSelector(
    (state) => state?.summary?.alarms_detected_count,
  );

  useEffect(() => {
    setLoading(true);

    Promise.all([dispatch(getDashboardDataAPI()), dispatch(getDeviceAPI())])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const onViewAll = () => {
    navigate("/controllers/");
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Breadcrumbs />
      <Grid {...styles.topGrid}>
        <GridItem {...styles.topContainerWatchlist}>
          <DashboardUserAssetCountSection
            device_count={devices_count}
            active_devices_count={active_devices_count}
            alarms_detected_count={alarms_detected_count}
          />

          <Box
            mt={6}
            // mt={recently_updated_devices > 0 ? -30 : 20}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Text
              fontFamily="'Poppins', sans-serif"
              fontWeight={600}
              fontSize={14}
              fontStyle="normal"
              letterSpacing=".05em"
              textTransform="uppercase">
              Recently Active Devices
            </Text>

            <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
              <Box>
                <Button
                  {...styles.button}
                  // rightIcon={<AddIcon />}
                  onClick={() => onViewAll()}>
                  View All
                </Button>
              </Box>
              <Spacer />
              <Flex ml={4}>
                <AddDeviceModal
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  setIsModalOpen={setIsModalOpen}
                  type="Add Controller"
                  getDevices={getDevices}
                />
              </Flex>
            </Box>
          </Box>
          <DeviceWatchlist device_list={recently_updated_devices} />
        </GridItem>
        {/* <GridItem {...styles.sideList}>
          <Card {...styles.recentAlertsCard}>
            <CardHeader>
              <Heading {...styles.cardHeader}>Recent Devices</Heading>
            </CardHeader>
            <Center>
              <Divider {...styles.divider} />
            </Center>
            <CardBody>
              <RecentlyAddedDevices
                recent_devices={recently_added_devices}
                is_update={true}
              />
            </CardBody>
          </Card>
        </GridItem> */}
        {/* <GridItem {...styles.sideList}>
          <ReactECharts option={options} style={styles.chartStyles} />
        </GridItem> */}
        {/* <GridItem>
          <List>
            <ListItem> <Text > OK </Text> </ListItem>
          </List>
        </GridItem> */}
        {/* <GridItem {...styles.sideList}>
          <Card {...styles.recentAlertsCard}>
            <CardHeader>
              <Heading {...styles.cardHeader}>Team Members</Heading>
            </CardHeader>
            <Center>
              <Divider {...styles.divider} />
            </Center>
            <CardBody>
              <DashboardTeamMembers team_members={team_members} />
            </CardBody>
          </Card>
        </GridItem> */}
      </Grid>
    </Box>
  );
}

export default Dashboard;
