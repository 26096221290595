/**
 * Used to Manage Devices for WG
 */
import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addDeviceAPI = createAsyncThunk(
  "addDevice",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/customer/device`,
        { serial_number: payload?.serial_number },
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getDeviceAPI = createAsyncThunk(
  "getDevice",
  async (token, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/customer/device`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getSingleDeviceAPI = createAsyncThunk(
  "getSingleDevice",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(
        `/customer/device?serial_number=${payload.serial_number}`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getDeviceDataPointsAPI = createAsyncThunk(
  "getDeviceDataPoints",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(
        `/customer/device/${payload?.device_id}/data-points`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const updateDeviceAPI = createAsyncThunk(
  "updateDevice",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.put(
        `/customer/device/${payload?.device_id}`,
        {
          device_name: payload?.device_name,
          data_points: payload?.data_points,
          // data_points: [
          //   {
          //     data_point_id: payload?.data_point_id,
          //     min: payload?.min_pressure,
          //     max: payload?.max_pressure,
          //     unit_id: payload?.unit_id,
          //   },
          // ],
        },
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const deleteDeviceAPI = createAsyncThunk(
  "deleteDeviceAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.delete(
        `/customer/unassign/device/${payload.device_assignment_id}`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const deviceConfigHistoryAPI = createAsyncThunk(
  "deviceConfig",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(
        `/customer/device/${payload?.device_id}/configuration-history`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);
