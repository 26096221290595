import { Button, Flex, List, ListItem, Text, theme } from "@chakra-ui/react";
import ReactECharts from "echarts-for-react";
import PropTypes from "prop-types";
import React from "react";
import { BiBell, BiBoltCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setActiveDevice } from "../redux/reducers/controllerReducer";
// import * as echarts from "echarts"; // Uncomment this when using https://echarts.apache.org/examples/en/editor.html?c=area-time-axis

function DeviceWatchlist({ device_list }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const devices = useSelector((state) => state?.controller?.devices);

  // ********* Use this for https://echarts.apache.org/examples/en/editor.html?c=area-time-axis chart ********
  // let base = +new Date(2024, 9, 3);
  // let oneDay = 24 * 3600 * 1000;
  // let date = [];
  // let data = [Math.random() * 100];
  // for (let i = 1; i < 20; i++) {
  //   var now = new Date((base += oneDay));
  //   date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"));
  //   data.push(Math.round(Math.random() * 20));
  // }
  // const options = {
  //   tooltip: {
  //     trigger: "axis",
  //     position: function (pt) {
  //       return [pt[0], "10%"];
  //     },
  //   },
  //   // title: {
  //   //   left: "center",
  //   //   text: "Large Ara Chart",
  //   // },
  //   // toolbox: {
  //   //   feature: {
  //   //     dataZoom: {
  //   //       yAxisIndex: "none",
  //   //     },
  //   //     restore: {},
  //   //     saveAsImage: {},
  //   //   },
  //   // },
  //   xAxis: {
  //     show: false,
  //     type: "time",
  //     boundaryGap: false,
  //   },
  //   yAxis: {
  //     show: false,
  //     type: "value",
  //     boundaryGap: [0, "100%"],
  //   },

  //   series: [
  //     {
  //       name: "Fake Data",
  //       type: "line",
  //       smooth: false,
  //       symbol: "none",
  //       // areaStyle: {},
  //       data: data,
  //       itemStyle: {
  //         color: "rgb(255, 70, 131)",
  //       },
  //       areaStyle: {
  //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
  //           {
  //             offset: 0,
  //             color: "rgb(255, 158, 68)",
  //           },
  //           {
  //             offset: 1,
  //             color: "rgb(255, 70, 131)",
  //           },
  //         ]),
  //       },
  //     },
  //   ],
  // };

  // ******* Uncomment end here *******

  // Use this for https://echarts.apache.org/examples/en/editor.html?c=dynamic-data2 chart
  function randomData() {
    now = new Date(+now + oneDay);
    value = value + Math.random() * 21 - 10;
    return {
      name: now.toString(),
      value: [
        [now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"),
        Math.round(value),
      ],
    };
  }

  let data = [];
  let now = new Date(1997, 9, 3);
  let oneDay = 24 * 3600 * 1000;
  let value = 10;
  for (var i = 0; i < 1000; i++) {
    data.push(randomData());
  }
  const options = {
    // title: {
    //   text: "Dynamic Data & Time Axis",
    // },
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        params = params[0];
        var date = new Date(params.name);
        return (
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear() +
          " : " +
          params.value[1]
        );
      },
      axisPointer: {
        animation: false,
      },
    },
    xAxis: {
      show: false,
      type: "time",
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      show: false,
      type: "value",
      boundaryGap: [0, "100%"],
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: "Heartbeat Data",
        type: "line",
        data: data,
        smooth: false,
        showSymbol: false,
        lineStyle: {
          color: "red",
          width: 2,
        },
        areaStyle: {
          opacity: 0.15,
          color: "red",
        },
      },
    ],
  };

  // ***** Uncomment end here *****

  const onClickDevice = (selectedDevice) => {
    const completeDeviceInfo = devices.filter(
      (device) => device.id == selectedDevice?.id,
    );

    dispatch(setActiveDevice(completeDeviceInfo[0]));
    console.log("onClickDevice", selectedDevice, completeDeviceInfo);
    navigate("/controllers/device", { state: { selectedDevice } });
  };

  return (
    <>
      {device_list?.map((device, index) => {
        return (
          // <Flex key={index} flexDirection={'column'}>
          <List key={index}>
            <ListItem
              my={4}
              bg={"white"}
              flexDirection={"row"}
              display={"flex"}
              justifyContent={"space-between"}
              cursor={"pointer"}
              onClick={() => onClickDevice(device)}
              borderRadius={3}>
              <Flex
                flex={1.5}
                p={4}
                flexDirection={"row"}
                alignItems={"flex-start"}
                justify={"flex-start"}>
                <Flex flexDirection={"column"} justifyContent={"flex-start"}>
                  <BiBoltCircle color={theme.colors.teal[400]} size={22} />
                </Flex>

                <Flex
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  ml={2}>
                  <Text
                    fontWeight={600}
                    textTransform={"capitalize"}
                    fontFamily="'Barlow', sans-serif"
                    fontStyle="normal"
                    letterSpacing="0.05rem"
                    fontSize={16}>
                    {device?.name}
                  </Text>
                  <Text
                    fontFamily="'Barlow', sans-serif"
                    fontStyle="normal"
                    letterSpacing="0.05rem"
                    fontSize={14}
                    mt={2}>
                    # {device?.serial_number}{" "}
                  </Text>
                </Flex>
              </Flex>

              <Flex
                flex={1}
                p={4}
                flexDirection={"row"}
                alignItems={"flex-start"}
                justify={"flex-start"}>
                <Flex
                  flexDirection={"column"}
                  justifyContent={"flex-start"}
                  ml={2}>
                  <Text
                    fontWeight={600}
                    textTransform={"capitalize"}
                    fontFamily="'Barlow', sans-serif"
                    fontStyle="normal"
                    letterSpacing="0.05rem"
                    fontSize={16}>
                    {device?.device_model?.name}
                  </Text>
                  <Text
                    fontFamily="'Barlow', sans-serif"
                    fontStyle="normal"
                    letterSpacing="0.05rem"
                    fontSize={14}
                    mt={2}>
                    {device?.device_type?.name}
                  </Text>
                </Flex>
              </Flex>

              <Flex
                flex={1}
                p={4}
                flexDirection={"column"}
                justifyContent={"flex-start"}
                alignItems={"end"}>
                {device?.data_points?.map((dp) => {
                  return (
                    <Text
                      key={dp.id}
                      fontWeight={600}
                      textTransform={"capitalize"}
                      fontFamily="'Barlow', sans-serif"
                      fontStyle="normal"
                      letterSpacing="0.05rem"
                      fontSize={16}>
                      {dp?.minimum} {dp?.unit?.unit}
                    </Text>
                  );
                })}
                <Button
                  leftIcon={<BiBell />}
                  variant={"ghost"}
                  colorScheme="red"
                  borderRadius={3}
                  size={"sm"}>
                  2
                </Button>
              </Flex>

              <Flex
                flex={1}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"center"}>
                <ReactECharts
                  style={{ width: 400, height: 50 }}
                  option={options}
                />
                <Text fontSize={12} fontFamily="'Barlow', sans-serif" mt={2}>
                  Last updated : {new Date(device.updated_at).toLocaleString()}
                </Text>
              </Flex>
            </ListItem>
          </List>
        );
      })}
    </>
  );
}
DeviceWatchlist.propTypes = {
  device_list: PropTypes.array.isRequired,
};

export default DeviceWatchlist;
