import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./header";
import SidebarMenu from "./sidebarMenu";
import { useDispatch, useSelector } from "react-redux";
import { customerProfileAPI } from "../redux/helpers/authAPI";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";

export default function Layout() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter(Boolean);

  const token = useSelector((state) => state?.auth?.token);

  const authState = useSelector((state) => state?.auth?.is_authenticated);
  const isSetupCompleate = useSelector(
    (state) => state?.auth?.is_setup_complete,
  );

  useEffect(() => {
    dispatch(customerProfileAPI(token));
    dispatch(getDashboardDataAPI());
    if (
      authState &&
      window.location.pathname === "/" &&
      isSetupCompleate === false
    ) {
      window.location.href = "/onboarding";
    } else if (
      authState &&
      window.location.pathname === "/" &&
      isSetupCompleate
    ) {
      window.location.href = "/dashboard";
    }
  }, []);

  const styles = {
    contentArea: {
      backgroundColor: "whitesmoke",
      width: "100%",
      height: "90vh",
      overflowY: "auto",
      padding: { base: "12px", md: "26px" },
    },
  };
  return (
    <div>
      {["login", "signup", "forgot-password", "onboarding"].includes(
        pathSegments[0],
      ) ? (
        <Outlet />
      ) : (
        <Box>
          <Header />
          <Flex>
            <SidebarMenu />
            <Box {...styles.contentArea}>
              <Outlet />
            </Box>
          </Flex>
        </Box>
      )}
    </div>
  );
}
