import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/layout";
import Dashboard from "../screens/dashboard";
import Controllers from "../screens/controllers";
import Team from "../screens/team";
import Alerts from "../screens/alerts";
import Settings from "../screens/settings";
import Device from "../screens/device";
import { useSelector } from "react-redux";
import Login from "../screens/login";
import Signup from "../screens/signup";
import ForgotPassword from "../screens/forgotPassword";
import NotFound from "../screens/notFound";
import Onboarding from "../screens/onboarding";
import ResetPassword from "../screens/resetPassword";
import Profile from "../screens/profile";

export default function Navigations() {
  const authState = useSelector((state) => state?.auth?.is_authenticated);

  const router = createBrowserRouter([
    {
      id: "root",
      path: "/",
      Component: authState && authState ? Layout : Login,
      children: [
        {
          path: "/onboarding",
          Component: Onboarding,
        },
        {
          path: "/dashboard",
          Component: Dashboard,
        },
        {
          path: "/controllers",
          Component: Controllers,
        },
        {
          path: "/team",
          Component: Team,
        },
        {
          path: "/alerts",
          Component: Alerts,
        },
        {
          path: "/settings",
          Component: Settings,
        },
        {
          path: "/profile",
          Component: Profile,
        },
        {
          path: "/controllers/device",
          Component: Device,
        },
      ],
    },
    {
      path: "/login",
      Component: Login,
    },
    {
      path: "/signup",
      Component: Signup,
    },
    {
      path: "/forgot-password",
      Component: ForgotPassword,
    },
    {
      path: "/reset/password",
      Component: ResetPassword,
    },
    {
      path: "*",
      Component: NotFound,
    },
  ]);
  return router;
}
