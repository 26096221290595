import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Center,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { GoDotFill } from "react-icons/go";
import PropTypes from "prop-types";
import EditDeviceModal from "./editDeviceModal";
import { BiTrash } from "react-icons/bi";

const ControllersTable = ({
  devices,
  handleRowClick,
  getDevices,
  handleDeleteModalOpen,
}) => {
  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "56vh",
    },
    indecatorBox: {
      mb: -1,
      pr: 2,
    },
    deviceName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    deviceModel: {
      fontSize: 13,
    },
    latestVersionText: {
      fontSize: 12,
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      fontSize: "12px",
      borderRadius: 3,
      borderColor: "#2b3642",
      width: "70%",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },
  };

  useEffect(() => {
    getDevices();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);

  // const handleModelOpen = () => {
  //   setIsModalOpen(true);
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModifyClick = (device) => {
    setSelectedDevice(device);
    setIsModalOpen(true);
  };
  return (
    <>
      <TableContainer {...styles.tableContainer}>
        <Table size={"sm"} variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th width="40%">LOCATION</Th>
              <Th width="12%">TYPE</Th>
              <Th width="12%">FIRMWARE</Th>
              <Th width="12%">THRESHOLD </Th>
              <Th width="12%">ACTIONS</Th>
            </Tr>
          </Thead>
          <Tbody>
            {devices?.map((device, index) => {
              return (
                <Tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(device)}>
                  <Td>
                    <Flex>
                      <Center>
                        <Box {...styles.indecatorBox}>
                          <GoDotFill
                            color={
                              device?.status === "online" ? "green" : "green"
                            }
                          />
                        </Box>
                        <Box>
                          <Text
                            fontWeight={600}
                            textTransform={"capitalize"}
                            fontFamily="'Barlow', sans-serif"
                            fontStyle="normal"
                            letterSpacing="0.05rem"
                            fontSize={14}>
                            {device?.name == null ? "unassigned" : device?.name}
                          </Text>
                          <Text
                            fontFamily="'Barlow', sans-serif"
                            fontStyle="normal"
                            letterSpacing="0.05rem"
                            fontSize={14}>
                            #{device?.serial_number}
                          </Text>
                        </Box>
                      </Center>
                    </Flex>
                  </Td>
                  <Td>
                    <Text
                      fontFamily="'Barlow', sans-serif"
                      fontStyle="normal"
                      letterSpacing="0.05rem"
                      fontSize={14}>
                      {device?.device_type?.name}
                    </Text>
                    <Text
                      fontFamily="'Barlow', sans-serif"
                      fontStyle="normal"
                      letterSpacing="0.05rem"
                      fontSize={14}>
                      {device?.device_model?.name}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontFamily="'Barlow', sans-serif"
                      fontStyle="normal"
                      letterSpacing="0.05rem"
                      fontSize={14}>
                      {device?.firmware?.version_number}
                    </Text>
                  </Td>
                  {device?.data_points ? (
                    <Td>
                      <Text
                        fontFamily="'Barlow', sans-serif"
                        fontStyle="normal"
                        letterSpacing="0.05rem"
                        fontSize={14}>
                        {device?.data_points[0]?.minimum}{" "}
                        {device?.data_points[0]?.unit?.unit} -{" "}
                        {device?.data_points[0]?.maximum}{" "}
                        {device?.data_points[0]?.unit?.unit}
                      </Text>
                    </Td>
                  ) : (
                    <Td>{null}</Td>
                  )}

                  <Td
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center">
                    <Button
                      {...styles.actionButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleModifyClick(device);
                      }}>
                      Modify
                    </Button>
                    <BiTrash
                      color="red"
                      fontSize={22}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteModalOpen(device);
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap={2} pt={2}>
        <Flex>
          <Center>
            <GoDotFill color={"green"} />
            Online
          </Center>
        </Flex>
        <Flex>
          <Center>
            <GoDotFill color={"red"} />
            Offline
          </Center>
        </Flex>
      </Flex>
      {isModalOpen && (
        <EditDeviceModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          type="Edit Controller"
          device={selectedDevice}
          getDevices={getDevices}
        />
      )}
    </>
  );
};

ControllersTable.propTypes = {
  devices: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  getDevices: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func.isRequired,
};

export default ControllersTable;
