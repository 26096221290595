import { Button, ModalFooter, Spacer } from "@chakra-ui/react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { removeMemberAPI } from "../redux/helpers/teamAPI";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./loader";

export default function ModalFooterButtons({
  onClose,
  onSave,
  type,
  teamMember,
  setIsModalOpen,
  refreshTeamMembers,
  openSuccessModal,
  successMessage,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state?.auth?.token);
  const userProfile = useSelector((state) => state?.auth?.userProfile);

  const styles = {
    cancelButton: {
      mr: 3,
      size: "sm",
      borderRadius: 3,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
      fontSize: "12px",
    },
    submitButton: {
      colorScheme: "green",
      size: "sm",
      borderRadius: 3,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
      fontSize: "12px",
    },
    removeButton: {
      colorScheme: "red",
      size: "sm",
      borderRadius: 3,
      fontSize: "12px",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },
  };

  const removeMember = () => {
    const payload = {
      member_id: teamMember?.id,
      token: token,
    };
    setLoading(true);
    try {
      let removeMemberRes = dispatch(removeMemberAPI(payload));

      removeMemberRes
        .then((data) => {
          setLoading(false);
          console.log("onRemoveMemberResponse", data);
          setIsModalOpen(false);
          refreshTeamMembers();
          openSuccessModal(true);
          successMessage("Team Member removed successfuly!");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  return (
    <>
      <Loader loading={loading} />
      <ModalFooter
        style={{
          padding: 0,
          marginTop: 15,
          marginBottom: 15,
        }}>
        {type === "Edit Team Member" &&
        userProfile?.email != teamMember?.email ? (
          <Button {...styles.removeButton} onClick={() => removeMember()}>
            Remove
          </Button>
        ) : null}
        <Spacer />
        <Button onClick={onClose} {...styles.cancelButton}>
          Cancel
        </Button>
        <Button type="submit" onClick={onSave} {...styles.submitButton}>
          Submit
        </Button>
      </ModalFooter>
    </>
  );
}

ModalFooterButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  teamMember: PropTypes.object,
  setIsModalOpen: PropTypes.func,
  refreshTeamMembers: PropTypes.func,
  openSuccessModal: PropTypes.func,
  successMessage: PropTypes.func,
};
