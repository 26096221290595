import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ModalFooterButtons from "./modalFooterButtons";
import { Field, Formik, FieldArray } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  updateDeviceAPI,
  getDeviceDataPointsAPI,
} from "../redux/helpers/deviceAPI";
import { MinusIcon } from "@chakra-ui/icons";
import Loader from "./loader";

const EditDeviceModal = ({ isOpen, onClose, device, type, getDevices }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [SlnoVerified, setSlnoVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const nameRegex = /^[a-zA-Z0-9\s-]+$/;
  const AddDeviceValidationSchema = Yup.object().shape({
    device_name: Yup.string()
      .matches(
        nameRegex,
        "Device name must contain only letters,numbers,hyphens and spaces",
      )
      .max(30, "Device Name must be at most 30 characters."),
    // min_pressure: Yup.string().required("Min value is required"),
    // max_pressure: Yup.string().required("Max value is required"),

    // gdata_point: Yup.array().of(
    //   Yup.object().shape({
    //     minimum: Yup.number().required('Min is required').min(0, 'Min must be at least 0'),
    //     maximum: Yup.number().required('Max is required').min(Yup.ref('min'), 'Max must be greater than Min'),
    //   })
    // ),
  });

  useEffect(() => {
    if (type == "Edit Controller") {
      setSlnoVerified(true);
    }
  }, [type, device]);

  useEffect(() => {
    dispatch(getDeviceDataPointsAPI({ device_id: device.id })).then(
      (result) => {
        console.log("getDeviceDataPointsAPI", result);
      },
    );
  }, []);

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 3,
    },
  };

  console.log("devive", device);

  return (
    <div>
      <Loader loading={loading} />
      <Modal
        blockScrollOnMount={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{type}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                serial_number: device?.serial_number,
                device_name: device?.data_points[0]?.device?.name,
                device_type: device?.device_type?.name,
                device_model: device?.device_model?.name,
                data_point: [],
                gdata_point: device?.data_points,
                // min_pressure: device?.data_points[0]?.minimum,
                // max_pressure: device?.data_points[0]?.maximum,
              }}
              validationSchema={AddDeviceValidationSchema}
              onSubmit={(values) => {
                console.log("values", values);
                let data_point = [];
                values.gdata_point.forEach((element) => {
                  data_point.push({
                    data_point_id: element.id,
                    max: element.maximum,
                    min: element.minimum,
                    unit_id: element.unit.id,
                    monitoring_value: element.monitoring_value,
                  });
                });

                console.log("data_pointdata_point", data_point);

                const payload = {
                  device_name: values?.device_name,
                  device_id: device?.id,
                  data_points: data_point,
                };
                setLoading(true);
                try {
                  let updateDeviceRes = dispatch(updateDeviceAPI(payload));
                  updateDeviceRes
                    .then((data) => {
                      if (data?.payload?.success) {
                        onClose();
                        getDevices();
                        setLoading(false);
                        toast({
                          title: "Success",
                          description: data.payload.message,
                          status: "success",
                          duration: 6000,
                          isClosable: true,
                        });
                      } else {
                        setLoading(false);
                        toast({
                          title: "Error",
                          description: data.payload.message,
                          status: "error",
                          duration: 6000,
                          isClosable: true,
                        });
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    });
                } catch (err) {
                  setLoading(false);
                  console.log(err);
                }
              }}>
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    isInvalid={errors.serial_number && touched.serial_number}>
                    <FormLabel>Serial Number</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      borderRightRadius={0}
                      ref={initialRef}
                      id="serial_number"
                      name="serial_number"
                      type="text"
                      placeholder="Ex: 17466144666"
                      value={values.serial_number}
                      isDisabled={SlnoVerified}
                      onChange={(e) => {
                        handleChange(e.target.value);
                        setFieldValue("serial_number", e.target.value);
                      }}
                    />
                    <FormErrorMessage>{errors?.serial_number}</FormErrorMessage>
                  </FormControl>

                  <>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.device_name && touched.device_name}>
                      <FormLabel>Location</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="device_name"
                        name="device_name"
                        type="text"
                        placeholder="Device"
                      />
                      <FormErrorMessage>{errors?.device_name}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.device_type && touched.device_type}>
                      <FormLabel>Type</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="device_type"
                        name="device_type"
                        type="text"
                        placeholder="Type"
                        isDisabled={SlnoVerified}
                      />
                      <FormErrorMessage>{errors?.device_type}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.device_model && touched.device_model}>
                      <FormLabel>Model</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="device_model"
                        name="device_model"
                        type="text"
                        placeholder="Model"
                        isDisabled={SlnoVerified}
                      />
                      <FormErrorMessage>
                        {errors?.device_model}
                      </FormErrorMessage>
                    </FormControl>

                    <Text mt={10} fontWeight={"bold"}>
                      {" "}
                      Threshold Configuration{" "}
                    </Text>

                    <FieldArray
                      name="gdata_point"
                      render={() => (
                        <div>
                          {values?.gdata_point?.map((ldata_point, index) => (
                            <Box
                              key={ldata_point.id}
                              {...styles.formItem}
                              marginBottom={6}>
                              <FormControl>
                                <FormLabel>
                                  {/* {ldata_point?.unit?.type}
                                <Text fontSize={10}>
                                  (in {ldata_point?.unit?.unit} )
                                </Text>
                              </FormLabel> */}
                                  <Text>{`Monitoring Value ${index + 1}`}</Text>
                                </FormLabel>
                                <Input
                                  {...styles.input}
                                  mb={6}
                                  mt={2}
                                  placeholder={`Monitoring Value`}
                                  name={`gdata_point.${index}.monitoring_value`}
                                  defaultValue={ldata_point?.unit?.type}
                                  onChange={handleChange}
                                />
                                <FormErrorMessage>
                                  {errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    errors.device_attributes[index]
                                      .monitoring_value}
                                </FormErrorMessage>
                              </FormControl>

                              <Center gap={2}>
                                <FormControl
                                  isInvalid={
                                    errors.min_pressure && touched.min_pressure
                                  }>
                                  <Field
                                    as={Input}
                                    {...styles.input}
                                    placeholder="Min pressure in Pa"
                                    type={"number"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={ldata_point.minimum}
                                    name={`gdata_point.${index}.minimum`}
                                  />
                                  <FormErrorMessage>
                                    {errors?.min_pressure}
                                  </FormErrorMessage>
                                </FormControl>

                                <MinusIcon />

                                <FormControl
                                  isInvalid={
                                    errors.max_pressure && touched.max_pressure
                                  }>
                                  <Field
                                    as={Input}
                                    {...styles.input}
                                    placeholder="Max Pressure in Pa"
                                    type="number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={ldata_point.maximum}
                                    name={`gdata_point.${index}.maximum`}
                                  />
                                </FormControl>
                              </Center>
                            </Box>
                          ))}
                        </div>
                      )}
                    />
                    {device?.data_points?.map((data_point) => {
                      return (
                        <Box
                          display={"none"}
                          key={data_point?.id}
                          {...styles.formItem}>
                          <FormLabel>
                            {data_point?.unit?.type}
                            <Text fontSize={10}>
                              (in {data_point?.unit?.unit} )
                            </Text>
                          </FormLabel>
                          <Center gap={2}>
                            <FormControl
                              isInvalid={
                                errors.min_pressure && touched.min_pressure
                              }>
                              <Field
                                as={Input}
                                {...styles.input}
                                placeholder="Min pressure in Pa"
                                type={"number"}
                                min={-2000}
                                defaultValue={data_point?.minimum}
                                id={data_point?.id + data_point?.minimum}
                                name={data_point?.id + data_point?.minimum}
                              />
                              <FormErrorMessage>
                                {errors?.min_pressure}
                              </FormErrorMessage>
                            </FormControl>
                            <MinusIcon />
                            <FormControl
                              isInvalid={
                                errors.max_pressure && touched.max_pressure
                              }>
                              <Field
                                as={Input}
                                {...styles.input}
                                placeholder="Max Pressure in Pa"
                                type="number"
                                max={2000}
                                defaultValue={data_point?.maximum}
                                id="max_pressure"
                                name="max_pressure"
                                // onChange={(e) =>
                                //   setFieldValue(
                                //     "max_pressure",
                                //     parseInt(e.target.value, 10),
                                //   )
                                // }
                              />
                              <FormErrorMessage>
                                {errors?.max_pressure}
                              </FormErrorMessage>
                            </FormControl>
                          </Center>
                        </Box>
                      );
                    })}
                  </>

                  <ModalFooterButtons
                    onClose={onClose}
                    onSave={handleSubmit}
                    type={type}
                  />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
EditDeviceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  type: PropTypes.string.isRequired,
  teamMember: PropTypes.object,
  getDevices: PropTypes.func,
  device: PropTypes.object,
};
export default EditDeviceModal;
