import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import React from "react";

function Tags(data) {
  const styles = {
    tag: {
      size: data.size ? data.size : "md",
      mx: 1,
      variant: "subtle",
      colorScheme: data?.success ? "green" : "red",
    },
    tagIcon: {
      boxSize: "12px",
      as: data?.success ? CheckIcon : CloseIcon,
    },
  };
  return (
    <Tag {...styles.tag}>
      <TagLeftIcon {...styles.tagIcon} />
      <TagLabel>{data?.type}</TagLabel>
    </Tag>
  );
}

export default Tags;
