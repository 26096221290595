import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const loginAPI = createAsyncThunk("loginAPI", async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let response = await API.post(
      `/customer/signin`,
      {
        email: payload?.email,
        password: payload?.password,
      },
      config,
    );

    return response?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

export const signUpAPI = createAsyncThunk("signUpAPI", async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let response = await API.post(
      `/customer/sign-up`,
      {
        first_name: payload?.first_name,
        last_name: payload?.last_name,
        role_id: payload?.role_id,
        email: payload?.email,
        phone_number: payload?.phone_number,
        password: payload?.password,
        organization_name: payload?.organization_name,
        org_contact_person_first_name: payload?.org_contact_person_first_name,
        org_contact_person_last_name: payload?.org_contact_person_last_name,
        org_contact_person_email: payload?.org_contact_person_email,
        org_contact_person_phone_number:
          payload?.org_contact_person_phone_number,
      },
      config,
    );

    return response?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

export const sendPassResetLinkAPI = createAsyncThunk(
  "sendPassResetLinkAPI",
  async (payload) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let response = await API.post(
        `/customer/send-reset-password-link`,
        {
          email: payload?.email,
        },
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const verifyInvitationIdAPI = createAsyncThunk(
  "verifyInvitaionIdAPI",
  async (payload) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      const response = await API.get(
        `/customer/reset-password?invitation_id=${payload?.invitaion_id}`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const setNewPasswordAPI = createAsyncThunk(
  "setNewPasswordAPI",
  async (payload) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let response = await API.post(
        `/customer/reset-password?invitation_id=${payload?.invitaion_id}`,
        {
          new_password: payload?.new_password,
        },
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const customerProfileAPI = createAsyncThunk(
  "customerProfile",
  async (token) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      const response = await API.get(`/customer/customer-profile`, config);
      console.log(response, "response?");
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const userRolesAPI = createAsyncThunk("userRoles", async (token) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    const response = await API.get(`/customer/user-role`, config);
    console.log(response, "response?");
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});
