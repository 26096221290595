import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Spacer,
  Text,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdGraphicEq } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import EditDeviceModal from "./editDeviceModal";
import {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
} from "@chakra-ui/react";
import {
  deviceConfigHistoryAPI,
  getSingleDeviceAPI,
} from "../redux/helpers/deviceAPI";
import { setActiveDevice } from "../redux/reducers/controllerReducer";
import moment from "moment";

function DeviceDetails() {
  // const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const deviceData = useSelector((state) => state?.controller?.activeDevice);
  const configHistory = useSelector(
    (state) => state?.controller?.activeDeviceConfigHistory,
  );
  console.log(configHistory, "configHistory");

  useEffect(() => {
    const payload = {
      device_id: deviceData?.id,
    };
    dispatch(deviceConfigHistoryAPI(payload));
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModifyClick = (device) => {
    console.log("device", device);
    setIsModalOpen(true);
  };

  const getDevices = () => {
    let getDevicesRes = dispatch(
      getSingleDeviceAPI({ serial_number: deviceData.serial_number }),
    );
    getDevicesRes.then((data) => {
      console.log("data?.payload?.data", data?.payload?.data);
      dispatch(setActiveDevice(data?.payload?.data[0]));
    });
  };

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
    },
    avatar: {
      size: "lg",
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    firmwareContainer: {
      alignItems: "center",
    },
    actionText: {
      fontSize: "10px",
      color: "#30C268",
      mt: "1",
      ml: "2",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      fontSize: { base: "12px", md: "12px" },
    },
    greenIcon: {
      color: "#30C268",
    },
    pressureReadingBox: {
      borderRadius: 3,
      px: "4",
      py: "2",
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    pressureReadingTextContainer: {
      alignItems: "center",
    },
    pressureReadingText: {
      fontFamily: "'Barlow', sans-serif",
      fontStyle: "normal",
      letterSpacing: "0.05rem",
      fontSize: 16,
      fontWeight: "bold",
    },
    pressureReadingTopic: {
      fontFamily: "'Barlow', sans-serif",
      fontStyle: "normal",
      letterSpacing: "0.05rem",
      fontSize: 14,
    },
  };
  return (
    <>
      <Card size={{ base: "sm", md: "md" }} borderRadius={3}>
        <CardBody>
          <Flex {...styles.flexContainer}>
            <Avatar {...styles.avatar} name={deviceData?.name} />
            <Box>
              <Flex gap={2}>
                <Heading
                  {...styles.deviceHeader}
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem">
                  {deviceData?.name}
                </Heading>
              </Flex>

              <Flex mt={2} {...styles.subheaderItems}>
                <Heading
                  size={"xs"}
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem">
                  # {deviceData?.serial_number}
                </Heading>
              </Flex>
              <Flex {...styles.subheaderItems}>
                <Text
                  fontWeight={"normal"}
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem"
                  fontSize={14}>
                  {deviceData?.device_type?.name} {"|"}{" "}
                  {deviceData?.device_model?.name}
                </Text>
              </Flex>
              <Flex {...styles.firmwareContainer}>
                <Text
                  fontWeight={"normal"}
                  fontFamily="'Barlow', sans-serif"
                  fontStyle="normal"
                  letterSpacing="0.05rem"
                  fontSize={14}>
                  Firmware:{" "}
                  {deviceData?.firmware ? deviceData?.firmware : "0.0.1"}
                </Text>
                {/* <Text
                  {...styles.actionText}
                  color={
                    deviceData?.firmware == deviceData?.latestFirmware
                      ? "#30C268"
                      : "blue"
                  }>
                  {deviceData?.firmware == deviceData?.latestFirmware
                    ? "Latest"
                    : "Update"}
                </Text> */}
              </Flex>
              {/* {isMobile && (
              <Flex {...styles.subheaderItems}>
                <Text {...styles.subheaderText}>
                  Pressure Threshold:{" "}
                  {deviceData?.data_points
                    ? deviceData?.data_points[0]?.minimum
                    : null}{" "}
                  -{" "}
                  {deviceData?.data_points
                    ? deviceData?.data_points[0]?.maximum
                    : null}{" "}
                  Pa
                </Text>
                <SetPressureThreshold />
              </Flex>
            )} */}
            </Box>
            <Spacer />
            {/* {!isMobile && (
            <Box {...styles.pressureReadingBox}>
              <Flex {...styles.pressureReadingTextContainer}>
                {deviceData?.data_points ? (
                  <Text {...styles.pressureReadingText}>
                    {deviceData?.data_points[0]?.minimum} -{" "}
                    {deviceData?.data_points[0]?.maximum} Pa
                  </Text>
                ) : (
                  <Text> {null}</Text>
                )}

                <Spacer />
              </Flex>
              <Text {...styles.pressureReadingTopic}>Pressure Threshold</Text>
            </Box>
          )} */}
          </Flex>
        </CardBody>
      </Card>
      <Flex gap={4}>
        <Card
          borderRadius={3}
          size={{ base: "sm", md: "md" }}
          maxH={"30vh"}
          overflow={"auto"}
          width={"50%"}>
          <CardHeader>
            <Flex justifyContent={"space-between"}>
              <Text
                fontFamily="'Poppins', sans-serif"
                fontWeight={600}
                fontSize={14}
                fontStyle="normal"
                letterSpacing=".05em"
                textTransform="uppercase">
                Current Configuration
              </Text>
              <Button
                size={"sm"}
                colorScheme="green"
                borderRadius={3}
                fontSiz="12px"
                fontFamily={"'Poppins', sans-serif"}
                fontWeight={600}
                fontStyle="normal"
                letterSpacing={".05em"}
                textTransform="uppercase"
                onClick={() => handleModifyClick(deviceData)}>
                Modify
              </Button>
            </Flex>
          </CardHeader>
          <CardBody mt={0} pt={0}>
            {deviceData?.data_points?.map((dataPt) => {
              return (
                <RangeSlider
                  display={"none"}
                  isReadOnly
                  key={dataPt.id}
                  min={-100}
                  max={200}
                  aria-label={["min", "max"]}
                  defaultValue={[dataPt?.minimum, dataPt?.maximum]}>
                  <RangeSliderTrack bg="red.100">
                    <RangeSliderFilledTrack bg="tomato" />
                  </RangeSliderTrack>

                  <Tooltip
                    display={"none"}
                    hasArrow
                    bg="teal.500"
                    color="white"
                    placement="top"
                    isOpen={true}
                    //label={dataPt?.minimum}
                    label={`${dataPt?.minimum}` + `${dataPt?.unit?.unit}`}>
                    <RangeSliderThumb boxSize={6} index={0}>
                      <Box color="tomato" as={MdGraphicEq} />
                    </RangeSliderThumb>
                  </Tooltip>

                  <Tooltip
                    display={"none"}
                    hasArrow
                    bg="teal.500"
                    color="white"
                    placement="top"
                    isOpen={true}
                    //label={dataPt?.minimum}
                    label={`${dataPt?.maximum}` + `${dataPt?.unit?.unit}`}>
                    <RangeSliderThumb boxSize={6} index={1}>
                      <Box color="tomato" as={MdGraphicEq} />
                    </RangeSliderThumb>
                  </Tooltip>
                </RangeSlider>
              );
            })}

            <SimpleGrid
              minChildWidth="60px"
              mt={2}
              justifyContent="space-between">
              <Text {...styles.pressureReadingTopic}>Data Point</Text>
              <Text
                {...styles.pressureReadingText}
                fontWeight={"normal"}
                justifyContent="flex-end"
                display="flex">
                min
              </Text>
              <Text
                {...styles.pressureReadingText}
                fontWeight={"normal"}
                justifyContent="flex-end"
                display="flex">
                max
              </Text>
            </SimpleGrid>
            {deviceData?.data_points?.map((dataPt) => {
              return (
                <SimpleGrid
                  minChildWidth="60px"
                  spacing={4}
                  key={dataPt.id}
                  mt={2}
                  justifyItems="space-between">
                  <Text key={dataPt.id} {...styles.pressureReadingTopic}>
                    {dataPt?.unit?.type}
                  </Text>
                  <Text
                    key={dataPt.id}
                    {...styles.pressureReadingText}
                    justifyContent="flex-end"
                    display="flex">
                    {dataPt?.minimum} {dataPt?.unit?.unit}
                  </Text>
                  <Text
                    key={dataPt.id}
                    {...styles.pressureReadingText}
                    justifyContent="flex-end"
                    display="flex">
                    {dataPt?.maximum} {dataPt?.unit?.unit}
                  </Text>
                </SimpleGrid>
              );
            })}
          </CardBody>
        </Card>
        <Card
          borderRadius={3}
          size={{ base: "sm", md: "md" }}
          maxH={"30vh"}
          overflow={"auto"}
          width={"50%"}>
          <CardBody>
            <Box>
              <Text
                fontFamily="'Poppins', sans-serif"
                fontWeight={600}
                fontSize={14}
                fontStyle="normal"
                letterSpacing=".05em"
                textTransform="uppercase">
                Threshold Revisions{" "}
                {configHistory?.length ? `(${configHistory?.length})` : null}
              </Text>
              {configHistory?.map((configData) => {
                return (
                  <>
                    {/* <Box
                      key={configData.id}
                      display={"flex"}
                      justifyContent={"space-between"}
                      flexDirection={"row"}
                      alignItems={"center"}>
                      <Box>
                        <Text
                          key={configData.id}
                          {...styles.pressureReadingTopic}>
                          {moment(configData?.revision_timestamp).format(
                            "YYYY-MM-DD HH:mm:ss",
                          )}
                        </Text>
                        <Text
                          key={configData.id}
                          {...styles.pressureReadingTopic}>
                          {configData?.author?.email}
                        </Text>
                      </Box>

                      <Box
                        ml={10}
                        display={"flex"}
                        justifyContent={"space-around"}
                        flexDirection={"row"}>
                        <Box>
                          <Text
                            key={configData.id}
                            {...styles.pressureReadingText}>
                            min {configData?.minimum} {configData?.unit?.unit}
                          </Text>
                        </Box>
                        <Box ml={4}>
                          <Text
                            key={configData.id}
                            {...styles.pressureReadingText}>
                            max {configData?.maximum} {configData?.unit?.unit}
                          </Text>
                        </Box>
                      </Box>
                    </Box> */}
                    <SimpleGrid
                      minChildWidth="60px"
                      spacing={4}
                      key={configData.id}
                      mt={2}
                      display="flex"
                      alignItems="flex-end"
                      justifyContent="space-between">
                      <Flex direction="column">
                        <Text
                          key={configData.id}
                          {...styles.pressureReadingTopic}>
                          {moment(configData?.revision_timestamp).format(
                            "YYYY-MM-DD HH:mm:ss",
                          )}
                        </Text>
                        <Text
                          key={configData.id}
                          {...styles.pressureReadingTopic}>
                          {configData?.author?.email}
                        </Text>
                      </Flex>
                      <Text key={configData.id} {...styles.pressureReadingText}>
                        min {configData?.minimum} {configData?.unit?.unit}
                      </Text>
                      <Text key={configData.id} {...styles.pressureReadingText}>
                        max {configData?.maximum} {configData?.unit?.unit}
                      </Text>
                    </SimpleGrid>
                    <Divider />
                  </>
                );
              })}
            </Box>
          </CardBody>
        </Card>
      </Flex>

      {isModalOpen && (
        <EditDeviceModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          type="Edit Controller"
          device={deviceData}
          getDevices={getDevices}
        />
      )}
    </>
  );
}

export default DeviceDetails;
