import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ModalFooterButtons from "./modalFooterButtons";
import ModalsTriggerButton from "./modalsTriggerButton";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { userRolesAPI } from "../redux/helpers/authAPI";

const AddTeamMemberModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  onSave,
  type,
  teamMember,
  refreshTeamMembers,
  openSuccessModal,
  successMessage,
}) => {
  const dispatch = useDispatch();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const roles = useSelector((state) => state?.auth?.roles);
  const token = useSelector((state) => state?.auth?.token);

  useEffect(() => {
    dispatch(userRolesAPI(token));
  }, [token]);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const nameRegex = /^[a-zA-Z\s]+$/;
  const AddTeamValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(nameRegex, "First Name must contain only letters and spaces")
      .required("First Name is required")
      .max(36, "First Name must be at most 36 characters long"),
    last_name: Yup.string()
      .matches(nameRegex, "Last Name must contain only letters and spaces")
      .required("Last Name is required")
      .max(36, "Last Name must be at most 36 characters long"),
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(254, "Email must be at most 254 characters.")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]{6,14}$/, "Enter valid Phone Number (e.g., +81-1223-333)")
      .required("Phone Number is required"),
    privilege: Yup.string().required("Privilege is required"),
  });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 3,
    },
  };

  return (
    <div>
      {type !== "Edit Team Member" ? (
        <ModalsTriggerButton
          setIsModalOpen={setIsModalOpen}
          type="Team Member"
        />
      ) : null}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {type == "Edit Team Member"
              ? `Edit "${teamMember?.first_name} ${teamMember?.last_name}"`
              : type}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={
                type === "Edit Team Member" && teamMember
                  ? {
                      email: teamMember?.email,
                      first_name: teamMember?.first_name,
                      last_name: teamMember?.last_name,
                      phone_number: teamMember?.phone_number,
                      privilege:
                        roles.find((role) => role.name === teamMember.privilege)
                          ?.id || "",
                    }
                  : {
                      email: "",
                      first_name: "",
                      last_name: "",
                      phone_number: "",
                      privilege: "",
                    }
              }
              validationSchema={AddTeamValidationSchema}
              onSubmit={(values) => {
                console.log(values);
                if (type != "Add Team Member") {
                  const data = {
                    email:
                      teamMember?.email == values?.email ? "" : values?.email,
                    first_name:
                      teamMember?.first_name == values?.first_name
                        ? ""
                        : values?.first_name,
                    last_name:
                      teamMember?.last_name == values?.last_name
                        ? ""
                        : values?.last_name,
                    phone_number:
                      teamMember?.phone_number == values?.phone_number
                        ? ""
                        : values?.phone_number,
                    privilege:
                      roles.find((role) => role.name === teamMember.privilege)
                        ?.id == values?.privilege
                        ? ""
                        : values?.privilege,
                  };
                  const payload = Object.fromEntries(
                    Object.entries(data).filter(([, value]) => value !== ""),
                  );
                  onSave(payload);
                } else if (type == "Add Team Member") {
                  onSave(values);
                }
              }}>
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    isInvalid={errors.first_name && touched.first_name}>
                    <FormLabel>First Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      ref={initialRef}
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="john"
                    />
                    <FormErrorMessage>{errors?.first_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.last_name && touched.last_name}>
                    <FormLabel>Last Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Doe"
                    />
                    <FormErrorMessage>{errors?.last_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.phone_number && touched.phone_number}>
                    <FormLabel>Phone Number</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="phone_number"
                      name="phone_number"
                      type="tel"
                      placeholder="+81-1223-333"
                    />
                    <FormErrorMessage>{errors?.phone_number}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.email && touched.email}>
                    <FormLabel>Email</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="email"
                      name="email"
                      type="email"
                      placeholder="john_doe@example.com"
                    />
                    <FormErrorMessage>{errors?.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.privilege && touched.privilege}>
                    <FormLabel>Privilege</FormLabel>
                    <Select
                      {...styles.input}
                      placeholder="Select option"
                      name="privilege"
                      value={values.privilege}
                      onChange={(e) => {
                        handleChange(e.target.value);
                        console.log("e.target.value", e.target.value);
                        setFieldValue("privilege", e.target.value);
                      }}>
                      {roles.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors?.privilege}</FormErrorMessage>
                  </FormControl>

                  <ModalFooterButtons
                    onClose={onClose}
                    onSave={handleSubmit}
                    type={type}
                    teamMember={teamMember}
                    setIsModalOpen={setIsModalOpen}
                    refreshTeamMembers={refreshTeamMembers}
                    openSuccessModal={openSuccessModal}
                    successMessage={successMessage}
                  />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
AddTeamMemberModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  teamMember: PropTypes.object,
  refreshTeamMembers: PropTypes.func,
  openSuccessModal: PropTypes.func,
  successMessage: PropTypes.func,
};
export default AddTeamMemberModal;
