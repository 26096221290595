import {
  Grid,
  GridItem,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Congratulations from "../assets/images/congratulations.jpg";
import ModalBackground from "../assets/images/modalBackground.jpg";
import { addMemberAPI, getMemberAPI } from "../redux/helpers/teamAPI";
import AddDeviceModal from "./addDeviceModal";
import AddTeamMemberModal from "./addTeamMemberModal";
import Loader from "./loader";

// import * as Yup from "yup";
// import { clearConfigCache } from "prettier";

const Onboardingmodal = ({ isOpen, onclose }) => {
  const toast = useToast();
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  // const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state?.auth?.token);
  const user = useSelector((state) => state?.auth?.userProfile);

  const handleCloseDeviceModal = () => {
    setIsDeviceModalOpen(false);
  };

  const dispatch = useDispatch();

  const handleCloseTeamModal = () => {
    setIsTeamModalOpen(false);
  };

  const refreshTeamMembers = () => {
    dispatch(getMemberAPI(token));
  };

  const onSaveTeam = async (payload) => {
    console.log("onSave payload", payload);
    setLoading(true);
    try {
      const addMemberReq = dispatch(addMemberAPI(payload));
      addMemberReq
        .then((result) => {
          if (result?.payload?.success) {
            refreshTeamMembers();
            handleCloseTeamModal();
            setLoading(false);
            toast({
              title: "Success",
              description: result.payload.message,
              status: "success",
              duration: 6000,
              isClosable: true,
            });
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result.payload.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // const handleDevTypeOpen = () => {
  //   setIsDevTypeModalOpen(true);
  // };

  const styles = {
    sideimage: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgImage: Congratulations,
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    sideblank: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    main: {
      justifyContent: "center",
      alignItems: "center",
      w: "80%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    row: {
      marginY: "5%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    listrow: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "row",
    },
    listtext: {
      w: "60%",
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "green",
    },
    listbutton: {
      w: "40%",
    },
    mainrow: {
      background: ModalBackground,
      p: 0,
    },
  };

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}
      <Loader loading={loading} />
      <Modal isOpen={isOpen} onClose={onclose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Complete Your Account Setup</ModalHeader>
          <ModalCloseButton />
          <ModalBody {...styles.mainrow}>
            <Grid
              {...styles.row}
              // templateAreas={`"nav main main"`}
              // gridTemplateRows={"50px 1fr 30px"}
              // gridTemplateColumns={"100px 1fr"}
              // h="200px"
              gap="1">
              <GridItem {...styles.sideimage} />
              <GridItem {...styles.main}>
                <Text>
                  <b>Hello </b>
                  {user?.first_name
                    ? user?.first_name + " " + user?.last_name
                    : ""}
                  !
                </Text>
                <Text>
                  Welcome aboard! The App is designed to streamline your
                  organization&apos;s IoT Device workflows. Let&apos;s get
                  started with a quick actions to familiarize with the platform.
                </Text>
              </GridItem>
              <GridItem {...styles.sideimage} />
            </Grid>
            <Grid
              {...styles.row}
              // templateAreas={`"nav main main"`}
              // gridTemplateRows={"50px 1fr 30px"}
              // gridTemplateColumns={"100px 1fr"}
              // h="200px"
              gap="1">
              <GridItem {...styles.sideblank} />
              <GridItem {...styles.main}>
                <Text>Few things you can do on this platform are </Text>
                <Text style={{ marginTop: "20px" }}>
                  <UnorderedList>
                    <Grid {...styles.listrow} gap="1">
                      <GridItem {...styles.listtext}>
                        <ListItem>Add controllers to your account</ListItem>
                      </GridItem>
                      <GridItem {...styles.listbutton}>
                        <AddDeviceModal
                          isOpen={isDeviceModalOpen}
                          onClose={handleCloseDeviceModal}
                          setIsModalOpen={setIsDeviceModalOpen}
                          // handleDevTypeOpen={handleDevTypeOpen}
                          // handleDevModelOpen={handleDevModelOpen}
                          // isModify={isOpenedForModify}
                        />
                      </GridItem>
                    </Grid>

                    <Grid {...styles.listrow} gap="1">
                      <GridItem {...styles.listtext}>
                        <ListItem>Collaborate with your team</ListItem>
                      </GridItem>
                      <GridItem {...styles.listbutton}>
                        <AddTeamMemberModal
                          isOpen={isTeamModalOpen}
                          onClose={handleCloseTeamModal}
                          setIsModalOpen={setIsTeamModalOpen}
                          onSave={onSaveTeam}
                          // teamMember={selectedTeamMember}
                          type={"Team Member"}
                        />
                        {/* {isSuccessModalOpen && (
                          <SuccessMessageModal
                            isOpen={isSuccessModalOpen}
                            message={`${successMessage}!`}
                            onClose={() => {
                              setisSuccessModalOpen(false);
                              // reloadDataForAccount();
                            }}
                          />
                        )} */}
                      </GridItem>
                    </Grid>
                  </UnorderedList>
                </Text>
              </GridItem>
              <GridItem {...styles.sideblank} />
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

Onboardingmodal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
};
export default Onboardingmodal;
