import { createSlice } from "@reduxjs/toolkit";
import {
  loginAPI,
  customerProfileAPI,
  userRolesAPI,
  signUpAPI,
  sendPassResetLinkAPI,
  verifyInvitationIdAPI,
  setNewPasswordAPI,
} from "../helpers/authAPI";

const initialState = {
  is_authenticated: false,
  is_admin: false,
  is_firstTime_user: false,

  users: [{ email: "", password: "" }],
  userProfile: null,
  is_new_user: false,
  secureCode: "",
  error: "",
  success: "",
  token: "",

  //UI loader
  loader_visible: false,
  loader_message: "Loading...",

  //roles of users
  roles: [],
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    creatUser: (state, action) => {
      state.users.push(action?.payload);
    },
    checkExistingUser: (state, action) => {
      const users = state?.users;
      if (users?.length === 0) {
        state.error = "No account found with the provided email address.";
      }
      for (const user of users) {
        if (user?.email === action?.payload?.email) {
          state.error =
            "An account with the provided email address already exists.";
        } else {
          state.error = "No account found with the provided email address.";
        }
        if (
          user?.email === action?.payload?.email &&
          user?.password !== action?.payload?.password
        ) {
          state.error = "Incorrect password. Please try again.";
        }
      }
    },
    clearMessage: (state) => {
      state.error = "";
      state.success = "";
    },
    loginUser: (state, action) => {
      const users = state?.users;
      for (const user of users) {
        if (
          user &&
          user?.email === action?.payload?.email &&
          user?.password === action?.payload?.password
        ) {
          state.is_authenticated = true;
        }
      }
    },
    changeAuthState: (state, action) => {
      state.is_authenticated = action?.payload;
    },
    sendSecureCode: (state, action) => {
      const users = state?.users;

      if (users?.length === 0) {
        state.error = "No account found with the provided email address.";
      }
      for (const user of users) {
        if (user?.email == action?.payload) {
          const secureCode = Math.floor(100000 + Math.random() * 900000);
          console.log("your secure code is - ", secureCode);
          state.secureCode = secureCode;
        } else if (user?.email != action?.payload);
        state.error = "No account found with the provided email address.";
      }
    },
    changePassword: (state, action) => {
      const users = state?.users;
      console.log(action?.payload, "action?.payload");
      for (const user of users) {
        if (user?.email == action?.payload?.email) {
          user.password = action?.payload?.password;
          state.success =
            "The password has been updated. Please log in to continue.";
        }
      }
    },
    setNewUserTour: (state, action) => {
      state.is_new_user = action?.payload;
    },
    resetState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login API handling
      .addCase(loginAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(loginAPI.fulfilled, (state, action) => {
        // state.is_authenticated = action?.payload?.is_authenticated;
        state.is_admin = action?.payload?.is_admin;
        state.token = action?.payload?.token;
        state.is_setup_complete = action?.payload?.is_setup_complete;
        state.loader_visible = false;
      })
      .addCase(loginAPI.rejected, (state, action) => {
        state.loader_visible = false;
        state.is_authenticated = false;
        state.error = action?.payload?.data?.error;
      })
      .addCase(signUpAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(signUpAPI.fulfilled, (state) => {
        state.loader_visible = false;
      })
      .addCase(signUpAPI.rejected, (state, action) => {
        state.error = action?.payload?.data?.error;
      })
      .addCase(sendPassResetLinkAPI.fulfilled, (state, action) => {
        console.log(action, "sendPassResetLinkAPI");
      })
      .addCase(sendPassResetLinkAPI.rejected, (state, action) => {
        console.log(action, "sendPassResetLinkAPI");
      })
      .addCase(verifyInvitationIdAPI.fulfilled, (state, action) => {
        console.log(action, "verifyInvitaionIdAPI");
      })
      .addCase(verifyInvitationIdAPI.rejected, (state, action) => {
        console.log(action, "verifyInvitaionIdAPI");
      })
      .addCase(setNewPasswordAPI.fulfilled, (state, action) => {
        console.log(action, "setNewPasswordAPI");
      })
      .addCase(setNewPasswordAPI.rejected, (state, action) => {
        console.log(action, "setNewPasswordAPI");
      })
      .addCase(customerProfileAPI.fulfilled, (state, action) => {
        state.userProfile = action?.payload?.data;
      })
      .addCase(customerProfileAPI.rejected, (state) => {
        state.userProfile = {};
      })

      .addCase(userRolesAPI.fulfilled, (state, action) => {
        state.roles = action?.payload?.data;
      });
  },
});

export const {
  changeAuthState,
  creatUser,
  loginUser,
  sendSecureCode,
  checkExistingUser,
  clearMessage,
  changePassword,
  setNewUserTour,
  resetState,
} = authSlice.actions;

export default authSlice.reducer;
