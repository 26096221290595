import { Flex } from "@chakra-ui/layout";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Onboardingmodal from "../components/onboardingModal";

function Onboarding() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const authData = useSelector((state) => state?.auth);

  const is_setup_complete = authData?.is_setup_complete;

  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/dashboard");
  };

  console.log("Onboarding", is_setup_complete);

  const styles = {
    background: {
      justifyContent: "center",
      alignItems: "center",
      h: "100vh",
      w: "100vw",
      bgGradient: "linear(to-br, #7928CA, #0762ef)",
      // bgImage: BackgroundImage,
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
  };
  return (
    <Flex {...styles.background}>
      {isModalOpen && (
        <Onboardingmodal isOpen={isModalOpen} onclose={handleCloseModal} />
      )}
    </Flex>
  );
}

export default Onboarding;
